import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BtnCsdm } from "../../shared/Presentational";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as Yup from "yup";

import API from "../../../__helpers/API";

const validationSchema = Yup.object({
  selectedHotel: Yup.string().required("Trebuie sa selectezi un hotel"),
  roomNo: Yup.string().required("Numarul de camera este obligatoriu"),
});

export default function SearchClient(props) {
  const classes = useStyles();
  const [hotelOptions, setHotelOptions] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchHotels = () => API.get(`/api/receptie/hotel/list`)
      .then((response) => {
        if (response.payload.length === 1) {
          setSelectedHotel(response.payload[0].value);
        }
        setHotelOptions(response.payload);
      })
      .catch(() => {
        enqueueSnackbar("Eroare de comunicare!", {
          variant: "error",
        });
      });

    fetchHotels();
  }, [enqueueSnackbar]);

  const searchClient = (values) => {
    setLoading(true);
    API.post("/api/receptie/customer/search", { ...values })
      .then((response) => {
        setLoading(false);
        if (!response.status) {
          enqueueSnackbar(response.error, { variant: "error" });
        } else {
          if (response.payload.length === 0) {
            enqueueSnackbar(
              "Nu a fost gasit nici un client conform selectiei.",
              {
                variant: "warning",
              }
            );
          } else {
            props.updateCustomer(response.payload);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar("Eroare la comunicare", { variant: "error" });
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ selectedHotel: selectedHotel, roomNo: "" }}
      validationSchema={validationSchema}
      onSubmit={searchClient}
    >
      {(props) => {
        const {
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form noValidate onSubmit={handleSubmit} autoComplete="off">
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="h1">
                  CAUTA CLIENT
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  className={classes.selectformControl}
                  variant="outlined"
                  fullWidth
                  error={errors.selectedHotel && touched.selectedHotel}
                >
                  <InputLabel>Selecteaza Hotel</InputLabel>
                  <Select
                    name="selectedHotel"
                    value={values.selectedHotel}
                    onChange={handleChange}
                  >
                    {!selectedHotel && (
                      <MenuItem value="">
                        <em>Alege</em>
                      </MenuItem>
                    )}

                    {hotelOptions &&
                      hotelOptions.map((option, key) => (
                        <MenuItem value={option.value} key={key}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {errors.selectedHotel &&
                      touched.selectedHotel &&
                      errors.selectedHotel}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Camera"
                    name="roomNo"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">NR.</InputAdornment>
                      ),
                    }}
                    type="text"
                    error={errors.roomNo && touched.roomNo}
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.roomNo && touched.roomNo && errors.roomNo
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs>
                <div className={classes.wrapper}>
                  <BtnCsdm
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    size={"large"}
                    disabled={loading}
                  >
                    Cauta
                  </BtnCsdm>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles((theme) => ({
  selectformControl: {
    minWidth: 200,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
