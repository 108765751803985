import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import SelectController from "components/shared/SelectController";
import BeachSectorBookingManagement from "./__components/BeachSectorBookingManagement";
import ConfirmClientReservationModal from "./__components/ConfirmClientReservationModal";
// import AddClientReservationModal from "./__components/AddClientReservationModal";
import API from "__helpers/API";
import { useSnackbar } from "notistack";

const BeachBookingManagement = props => {

    const [sectorsData, setSectorsData] = useState([]);
    const [sectorsCells, setSectorsCells] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedSector, setSelectedSector] = useState('');
    const [openClientConfirmationModal, setOpenClientConfirmationModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [reservationData, setReservationData] = useState({});
    const [isReservationCodeValid, setIsReservationCodeValid] = useState(false);
    const [reservationCodeErrorMessage, setReservationCodeErrorMessage] = useState('');
    const [confirmClientReservationButtonState, setConfirmClientReservationButtonState] = useState(false);
    const [sectorsCols, setSectorsCols] = useState({});
    const [sectorsRowsAliases, setSectorsRowsAliases] = useState({});
    // const [packageCustomers, setPackageCustomers] = useState({});

    const updateSectorsData = (requestData) => {
        setSectorsData(requestData.sectors);
        setSectorsCells(requestData.sectorsCells);
        setReservations(requestData.todayReservations);
        setSectorsCols(requestData.sectorsCols);
        setSectorsRowsAliases(requestData.sectorRowsAliases);
        // setPackageCustomers(requestData.packageCustomers);
    }

    const handleChangeSectorsSelector = event => {
        setSelectedSector(event.target.value);
    }

    const cellClickEvent = (cellData) => {

        const cellId = cellData.id;

        if ((reservations[cellId] !== undefined)
            && (reservations[cellId].is_confirmed !== undefined)
            && (reservations[cellId].is_confirmed === '0')
        ) {
            setReservationData(reservations[cellId]);
            setOpenClientConfirmationModal(true);
        }

    }

    const handleClientCloseReservationModal = event => {
        setReservationData({});
        setOpenClientConfirmationModal(false);
        setIsReservationCodeValid(true);
        setConfirmClientReservationButtonState(false);
        setReservationCodeErrorMessage('');
    }

    const handleReservationCodeChangeEvent = event => {

        const codeValue = event.target.value;

        if (codeValue.length < 5) {
            setIsReservationCodeValid(true);
            setReservationCodeErrorMessage('');

            return;
        }

        if ( codeValue.length > 5 ) {
            setIsReservationCodeValid(false);
            setReservationCodeErrorMessage('Codul de rezervare trebuie sa aiba 5 cifre.');

            return;
        }

        if (reservationData.card_number !== codeValue) {
            setIsReservationCodeValid(false);
            setReservationCodeErrorMessage('Codul de rezervare nu este valie.');

            return;
        }

        setIsReservationCodeValid(true);
        setReservationCodeErrorMessage('');
    }

    const handleSunbedReservationErrorResponse = errorMessage => {
        setErrorMessage(errorMessage);
        enqueueSnackbar(errorMessage, { variant: "error" });
        setConfirmClientReservationButtonState(false);
        setOpenClientConfirmationModal(false);
    }

    const handleSunbedReservationSuccessResponse = message => {

        const tempReservations = reservations;
        tempReservations[reservationData.seat_configuration_id].is_confirmed = '1';
        setReservations(tempReservations);

        setConfirmClientReservationButtonState(false);
        setOpenClientConfirmationModal(false);

        setReservationData({});
        enqueueSnackbar(message, { variant: "success" });
    }

    const handleConfirmReservation = event => {

        setConfirmClientReservationButtonState(true);

        API.post("/api/receptie/sunbeds/confirm/sunbed/reservation", {
            reservationId: reservationData.id
        })
        .then((response) => {
            (((response.status !== undefined) && (response.status === false))
            || ((response.payload.status !== undefined) && (response.payload.status === false))) ?
                handleSunbedReservationErrorResponse( response.error ?? (response.payload.message ?? 'Datele nu au putut fi salvate.')):
                handleSunbedReservationSuccessResponse(response.payload.message);

        })
        .catch((err) => {
            handleSunbedReservationErrorResponse("Eroare de comunicare");
            setErrorMessage("Eroare de comunicare");
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

    }

    useEffect(() => {

        API.get("/api/receptie/sunbeds/get/sectors", {})
        .then((response) => {
            response.status ?
                updateSectorsData(response.payload) :
                setErrorMessage(response.error);

        })
        .catch((err) => {
            setErrorMessage("Eroare de comunicare");
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

    },[]);

    return (
        <Paper elevation={2} className="ContainerSpacer">
            <Grid container spacing={2} >

                <Grid item xs={12}>
                    <h1 className="PageHeadline">Administrare Plaja</h1>
                </Grid>

                <Grid item md={6} sm={12} >
                    <SelectController
                        handleChangeSelector={handleChangeSectorsSelector}
                        options={sectorsData}
                        labelValue='Selectati Sectorul'
                        identifier='sectorSelector'
                    />
                </Grid>

                <Grid item xs sm={12} >
                    <BeachSectorBookingManagement
                        selectedSectorCells = {sectorsCells[selectedSector] ?? {}}
                        reservations = {reservations}
                        cells = { sectorsCols[selectedSector] ?? {} }
                        rowsAliases = { sectorsRowsAliases[selectedSector] ?? {} }
                        selectedSector = {selectedSector}
                        cellClickEvent = {cellClickEvent}
                    />
                </Grid>

                <Grid item xs sm={12} >
                    <ConfirmClientReservationModal
                        openClientConfirmationModal = {openClientConfirmationModal}
                        reservationData = {reservationData}
                        handleCloseModal = {handleClientCloseReservationModal}
                        handleInputChangeEvent = {handleReservationCodeChangeEvent}
                        isReservationCodeValid = {isReservationCodeValid}
                        reservationCodeErrorMessage = {reservationCodeErrorMessage}
                        handleConfirmReservation = {handleConfirmReservation}
                        confirmClientReservationButtonState = {confirmClientReservationButtonState}
                    />
                </Grid>

                {/* <Grid item xs sm={12} >
                    <AddClientReservationModal
                        handleCloseModal = {handleClientCloseReservationModal}
                    />
                </Grid> */}

            </Grid>
        </Paper>
    );

}

export default BeachBookingManagement;
