import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Intervals from "components/shared/Intervals";

const IntervalMealPlanner = ({ customer, classes }) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="h1"
          className={`${classes.infoText} ${classes.spacer}`}
          align="center"
        >
          Ca urmare a
          normelor de distantare COVID-19, impuse de autoritati si pentru
          siguranta dumneavoastra, am reorganizat servirea meselor in 3
          intervale prestabilitate, evitand astfel aglomerarile de persoane.
        </Typography>
        <Typography
          variant="body1"
          component="h1"
          className={`${classes.spacer} ${classes.infoTextSpecial}`}
          align="center"
        >
          <span style={{ display: "block" }}>
            Poti intra oricand intr-un interval de 60 de minute de la ora de
            start a programului, pentru a modifica intervalul ales initial.
          </span>{" "}
          Din respect pentru ceilalti turisti si pentru a evita aglomeratia,{" "}
          <span style={{ fontWeight: "bold" }}>
            va incurajam sa serviti masa intr-un interval de 60 minute.
          </span>
        </Typography>
        <Typography
          variant="body1"
          component="h1"
          align="center"
          className={classes.infoText}
        >
          Te rugam sa selectezi un interval preferat de servire a mesei din
          optiunile de mai jos.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Intervals
          customerIdentifier={customer.identifier}
          customer={customer}
          apiEndpointFetchIntervals={"/api/guest/customer/intervals"}
          apiEndpointChangeIntervals={
            "/api/guest/customer/change-interval-for-all-meals"
          }
        />
      </Grid>
    </React.Fragment>
  );
}

export default IntervalMealPlanner;