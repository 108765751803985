//import './App.css';
import Grid from './__components/LaungeChairReservationComponents/Grid';
import NavBar from './__components/LaungeChairReservationComponents/NavBar';
import Marea from './__components/LaungeChairReservationComponents/Marea';
import InfoBar from './__components/LaungeChairReservationComponents/InfoBar';
import { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Dialog, Button, IconButton, Typography, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import matrixData from './__components/LaungeChairReservationComponents/Grid/grid_sample.json';
import API from "../../__helpers/API";
import { useSnackbar } from "notistack";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);




const LaungeChairReservation = () => {
  const [openModalA, setOpenModalA] = useState(false);
  const [openModalB, setOpenModalB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [infoText, setInfoText] = useState('');
  const [changes, setChanges] = useState(0);
  const [matrix, setMatrix] = useState([]);
  const [placesGrid, setPlacesGrid] = useState([]);
  const [beach, setBeach] = useState(9877);
  const [reservedBeach, setReservedBeach] = useState('');
  const [place, setPlace] = useState('');
  const [reservationNumber, setReservationNumber] = useState('');
  const [placeID, setPlaceID] = useState('');
  const [placeDescription, setPlaceDescription] = useState('');
  const [lastPlace, setLastPlace] = useState('');
  const [lastPlaceID, setLastPlaceID] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    setLoadingGrid(true);
    var response = await getPlaces(JSON.parse(localStorage.getItem("currentUser")).token.toString());
    console.log(response);
    setMatrix(response.payload.seats);
    //setChanges(changes+1);
    if (response.payload && response.payload.currentReservation) {
      setReservationNumber(response.payload.currentReservation.reservationValidationCode);
      for (var i = 0; i < response.payload.seats.length; i++) {
        if (response.payload.seats[i].no === response.payload.currentReservation.reservationSeat) {
          setPlace({ col: response.payload.seats[i].col, row: response.payload.seats[i].row });
          setInfoText(<span>Ai rezervat umbrela <span className="highlightedText">{response.payload.seats[i].col}</span> de pe randul <span className="highlightedText">{response.payload.seats[i].row}</span> .  Prezinta codul <span className="highlightedText">{response.payload.currentReservation.reservationValidationCode}</span>.</span>);
        }
      }
      //  setPlace(response.payload.currentReservation.reservationSeat);
      setPlaceID(response.payload.currentReservation.reservationSeatId);

      setBeach('');
      setBeach(response.payload.currentReservation.reservationBeach);

    } else {
      var lastBeach = beach;
      setBeach('');
      setBeach(lastBeach);
    }
  }
  useEffect(() => {
    getData();
    //setMatrix(matrixData);
  }, []);

  const stopLoadingGrid = () => {
    setLoadingGrid(false);
  }

  useEffect(() => {
    setLoadingGrid(true);
    var updatedGrid = [];
    console.log(matrix);
    for (let i = 0; i < matrix.length; i++) {
      if (matrix[i].beach == beach) {
        updatedGrid.push(matrix[i]);
      }
    }
    console.log(updatedGrid);
    setPlacesGrid(updatedGrid);
    setChanges(changes + 1);

  }, [beach]);
  /*
    useEffect(() => {
      if (place !== '' && openModalB) {
        var updatedGrid = [];
        var temporaryGrid = matrix;
        for (let i = 0; i < temporaryGrid.length; i++) {
          if (temporaryGrid[i].no === place && temporaryGrid[i].beach == reservedBeach ) {
            temporaryGrid[i].status = 'reserved';
          }
          //else if (temporaryGrid[i].no === lastPlace) {
        //    temporaryGrid[i].status = 'free';
        //  }
          if (temporaryGrid[i].beach === beach) {
            updatedGrid.push(temporaryGrid[i]);
          }
        }
        setMatrix(temporaryGrid);
        setPlacesGrid(updatedGrid);
        setPlace(place);
        setLastPlace(place);
      }
  }, [matrix]);
  */


  const getPlaces = (identifier) => {
    return API.post(`/api/guest/seat/seats`, { identifier }).then(
      (response) => {
        if (!response.success) {
          enqueueSnackbar(response.error, { variant: "error" });
          return { payload: { seats: [] } };
        } else {
          return response;
        }
      }
    ).catch((err) => {
      enqueueSnackbar("Ceva nu a mers bine!" + err.message, {
        variant: "error",
      });
      return { payload: { seats: [] } };
    });
  }


  const reservePlace = (identifier, seat) => {
    return API.post(`/api/guest/seat/reserve`, { identifier, seat }).then(
      (response) => {
        if (response.error !== null) {
          enqueueSnackbar(response.error, { variant: "error" });
          return { payload: { validationCode: '' } };
        } else {
          return response;
        }
      }
    ).catch((err) => {
      enqueueSnackbar("Ceva nu a mers bine!" + err.message, {
        variant: "error",
      });
      return { payload: { validationCode: '' } };
    });
  }


  const removeReservation = (identifier, seat) => {
    return API.post(`/api/guest/seat/clear`, { identifier, seat }).then(
      (response) => {
        if (response.error !== null) {
          enqueueSnackbar(response.error, { variant: "error" });
          return { payload: { validationCode: '' } };
        } else {
          return response;
        }
      }
    ).catch((err) => {
      enqueueSnackbar("Ceva nu a mers bine!" + err.message, {
        variant: "error",
      });
      return { payload: { validationCode: '' } };
    });
  }

  const handleBeachChange = useCallback(async (number) => {
    setLoadingGrid(true);
    await getData();
    setBeach(number);

  }, []);

  const handleClickReservePlace = (number, numberID, numberDescription) => {
    setPlace(number);
    setPlaceDescription(numberDescription);
    setPlaceID(numberID);
    setLastPlace(number);
    setLastPlaceID(numberID)
    setReservedBeach(beach);
    setOpenModalA(true);
  };

  const handleConfirmModalA = async () => {
    setLoading(true);

    var response = await reservePlace(JSON.parse(localStorage.getItem("currentUser")).token.toString(), placeID);
    setOpenModalA(false);
    if (response.payload.validationCode !== '') {
      setReservationNumber(response.payload.validationCode)
      setInfoText(<span>Ai rezervat umbrela <span className="highlightedText">{place.col}</span> de pe randul <span className="highlightedText">{place.row}</span>.  Prezinta codul <span className="highlightedText">{response.payload.validationCode}</span>.</span>);
      setOpenModalB(true);
      await getData();
    }
    setLoading(false);
  };

  const handleCloseModalA = () => {
    setOpenModalA(false);
  };

  const handleReleasePlaces = async () => {
    var response = await removeReservation(JSON.parse(localStorage.getItem("currentUser")).token.toString(), placeID);
    await getData();
    setPlace('');
    setInfoText('');
  };

  const handleClickReleasePlace = async (number, numberID) => {
    var response = await removeReservation(JSON.parse(localStorage.getItem("currentUser")).token.toString(), numberID);
    await getData();
    setPlace('');
    setInfoText('');
  };

  const handleCloseModalB = () => {
    setOpenModalB(false);
  };
  const handleConfirmModalB = () => {
    setOpenModalB(false);
  };


  return (
    <div className="AppLaungeChair">
      <Marea></Marea>
      <InfoBar text={infoText} releasePlace={handleReleasePlaces}></InfoBar>
      <Grid stopLoading={stopLoadingGrid} loading={loadingGrid} reserveFunction={handleClickReservePlace} matrix={placesGrid} changes={changes} changeBeach={handleBeachChange} releaseFunction={handleClickReleasePlace} beach={beach}></Grid>

      <Dialog onClose={handleCloseModalA} aria-labelledby="customized-dialog-title" open={openModalA}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalA}>
          Confirmare umbrela
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom className="text-align-center">
            Ai ales umbrela <span className="highlightedText">{place.col}</span> de pe randul <span className="highlightedText">{place.row}</span>.
          </Typography>
          <Typography gutterBottom className="text-align-center">
            {placeDescription.capitalize()}
          </Typography>
          {loading && <span className='centered-flex'><CircularProgress /></span>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModalA} color="primary">
            Anuleaza
          </Button>
          <Button autoFocus onClick={handleConfirmModalA} color="primary">
            Confirma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={handleCloseModalB} aria-labelledby="customized-dialog-title" open={openModalB}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseModalB}>
          Rezervat
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom className="text-align-center">
            Prezinta Codul:
          </Typography>
          <Typography className="text-align-center highlightedText">{reservationNumber}</Typography>
          <Typography gutterBottom>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmModalB} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default LaungeChairReservation;
