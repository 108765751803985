import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function NestedGrid({ text, places, releasePlace }) {
  var releasePlaces;
  const classes = useStyles();
  if (text !== '') {
    releasePlaces = <Button className={classes.button} variant="contained" color="primary" onClick={releasePlace}>Elibereaza umbrela</Button>
  }
  return (
    <div className={classes.root}>
      <Typography>{text}</Typography>
      <span className="free-space-button">{releasePlaces}</span>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(20vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignOtems: 'center',
    textAlign: 'center'
  }, button: {
    background: "linear-gradient(45deg, #b49151 30%, #d3b070 90%)",
    color: theme.palette.common.white,
    padding: theme.spacing(2, 1.5),
    margin: theme.spacing(2, 0),
    fontWeight: 'bold'
  }
}));
