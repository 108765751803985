import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";

export default function DatePick(props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <DatePicker {...props} />
    </MuiPickersUtilsProvider>
  );
}
