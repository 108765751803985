import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import amber from "@material-ui/core/colors/amber";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import API from "../../../__helpers/API";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: amber[50],
    color: theme.palette.common.black,
    fontSize: 12,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: amber[300],
    },
  },
}));

export default function MoveAllMeals({ children, ...props }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleMealMove = () => {
    setDialogOpen(false);
    API.post("/api/receptie/customer/change-interval", { ...props })
      .then((response) => {
        if (!response.status) {
          enqueueSnackbar(response.error, { variant: "error" });
        } else {
          enqueueSnackbar("Alocarea a fost schimbata", { variant: "success" });
          props.handleAllocationChange(response.payload);
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          "Ceva nu a mers bine! Daca persista contacteaza administratia.",
          { variant: "error" }
        );
      });
  };

  return (
    <React.Fragment>
      <TableCell className={classes.head} onClick={() => setDialogOpen(true)}>
        {children}
      </TableCell>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esti sigur ca vrei sa muti ora de masa a clientului?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Nu
          </Button>
          <Button onClick={handleMealMove} color="primary" autoFocus>
            Da, sunt sigur!
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
