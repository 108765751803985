import React from "react";
import { Grid, Typography} from "@material-ui/core";
import { BtnCsdm } from "components/shared/Presentational";

const DisplayReservation = (props) => {

    return (
        <React.Fragment>

            {(props.currentReservation.isConfirmed === "0")
                && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                component="h1"
                                className={`${props.classes.spacer} ${props.classes.infoTextSpecial}`}
                                align="center"
                            >
                                Iti multumim si iti confirmam rezervarea efectuata pentru  ASTAZI <strong>{props.currentReservation.day}</strong> PLAJA <strong>{props.currentReservation.sector}</strong>, ZONA <strong>{props.currentReservation.seatPackage}</strong>, RANDUL <strong>{props.currentReservation.rowAlias}</strong>,
                                UMBRELA <strong>{props.currentReservation.colAlias}</strong> pana la ora <strong>{props.currentReservation.hour}</strong>
                                <br></br>
                                Vino la plaja pana la ora {props.currentReservation.hour} si prezinta TICHETUL DE PLAJA cu numarul
                                {props.currentReservation.cardNumber} angajatilor nostri din zona CONCIERGE de pe Plaja {props.currentReservation.sector} pentru a putea pastra locul ales de tine.
                                <br></br>
                                Daca nu inmanezi TICHETUL la plaja pana la ora {props.currentReservation.hour} locul va fi alocat automat altui client.
                                <br></br>
                                Poti schimba locul ales anuland acesta rezervare si efectuand una noua.
                                <br></br>
                                Atentie insa, locurile se ocupa foarte repede si este posibil sa nu mai gasesti disponibil acest loc in urmatoarele minute.
                            </Typography>
                            <br></br>
                            <BtnCsdm fullWidth disabled={props.loadingCancelReservation} onClick={props.handleCancelSunbedReservation} identifier = 'cancelSunbedReservation' >
                                Anuleaza rezervarea
                            </BtnCsdm>
                        </Grid>
                    </React.Fragment>
                )
            }

            {(props.currentReservation.isConfirmed === "1")
                && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                component="h1"
                                className={`${props.classes.spacer} ${props.classes.infoTextSpecial}`}
                                align="center"
                            >
                                Rezervare confirmata / Loc alocat
                                <br></br>
                                Ai confirmat deja UMBRELA <strong>{props.currentReservation.colAlias}</strong>, RANDUL <strong>{props.currentReservation.rowAlias}</strong>, ZONA <strong>{props.currentReservation.seatPackage}</strong>, PLAJA <strong>{props.currentReservation.sector}</strong>
                                <br></br>
                                Cand vrei sa pleci de la plaja nu uita sa vii la biroul concierge cu JETONUL pentru ati ridica TICHETUL DE PLAJA  astfel incat echipa noastra sa poata igieniza si aranja acest loc.
                                <br></br>
                                <br></br>
                                Numai dupa eliberarea locului poti primi TICHETUL DE PLAJA si efectua o alta rezervare sau alege alt loc.
                            </Typography>
                        </Grid>
                    </React.Fragment>
                )
            }

        </React.Fragment>
    )
}

export default DisplayReservation