import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function Dashboard() {
  return (
    <Grid container>
      <Grid item xs>
        <Typography variant="body1" component="h1">
          Alege optiune din mediul lateral
        </Typography>
      </Grid>
    </Grid>
  );
}
