import React, { useState, useEffect } from "react";
import { Typography, Button, Icon, withStyles } from "@material-ui/core";
import parse from 'html-react-parser';
import API from "__helpers/API";
import { useSnackbar } from "notistack";
import { DoneAll as ReservedIcon } from "@material-ui/icons";
const CsdmBtn = withStyles(theme => ({
    root: {
        padding: theme.spacing(2, 5),
        '&:hover': {
            backgroundColor: "#b49151",
            color: "white"
        }
    },
    'outlined': {
        border: "1px solid #b49151",
        color: '#b49151',
        fontWeight: "bold",
        '&.active': {
            backgroundColor: "green",
            color: "white"
        }
    },
}))((props) => <Button {...props} />);

const SkyBarBooking = ({ customerIdentifier, classes }) => {
    const [skyBarInfo, setSkyBarInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        API.post('/api/guest/customer/skybar/get-info', {
            identifier: customerIdentifier,
        }).then((response) => {
            if (response.status) {
                setSkyBarInfo(response.payload);
            }
        });
    }, [customerIdentifier]);

    const handleSkyBarReservation = (interval) => {
        setLoading(true);
        API.post('/api/guest/customer/skybar/make-reservation', {
            identifier: customerIdentifier,
            interval
        }).then((response) => {
            setLoading(false);
            if (response.status) {
                setSkyBarInfo(response.payload);
                enqueueSnackbar("Rezervarea a fost efectuata.", { variant: "success" });
            } else {
                enqueueSnackbar(response.error, { variant: "error" });
            }
        }).catch(err => {
            enqueueSnackbar("Ops, ceva nu a functionat corect", { variant: "error" });
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            {
                !skyBarInfo.hasSkyBarAccess && (
                    <Typography
                        variant="body1"
                        component="h1"
                        className={`${classes.spacer} ${classes.infoTextSpecial}`}
                        align="center"
                    >
                        <span style={{ display: "block" }}>
                            Optiunea SkyBar nu este activa.
                        </span>
                    </Typography>
                )
            }

            {
                skyBarInfo.hasSkyBarAccess && (
                    <>
                        <div>
                            <Typography
                                variant="body1"
                                component="h1"
                                className={`${classes.infoText} ${classes.spacer}`}
                                align="center"
                                style={{ marginTop: "0" }}
                            >
                                {skyBarInfo.name}
                            </Typography>
                            <span style={{ display: "block" }}>
                                {parse(skyBarInfo.description)}
                            </span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "2.5rem" }}>
                            {
                                skyBarInfo.intervals.map((interval, idx) => (
                                    <CsdmBtn variant="outlined"
                                        className={skyBarInfo.currentReservation === interval.interval ? 'active' : null} size="large"
                                        key={idx}
                                        onClick={() => handleSkyBarReservation(interval.interval)}
                                        disabled={interval.isDisabled || loading}
                                        endIcon={skyBarInfo.currentReservation === interval.interval ? <ReservedIcon /> : null}
                                    >
                                        {interval.interval}
                                    </CsdmBtn>
                                ))
                            }
                        </div>
                    </>
                )
            }

        </React.Fragment>
    )
}

export default SkyBarBooking;