import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  bar: {
    height: '10px',
    maxHeight: '10px',
    paddingLeft: '4px',
    marginRight: '2px'
  }
}));


const OccupancyBar = props => {

    const classes = useStyles();
    let barStyle = { };

    if (props.isEmptyBar === 1) {

        const barQuantum = Math.round(100/props.totalBars);
        const shouldBarBeColored = ((barQuantum* props.position) <= props.value);


        let color = 'gray';
        switch(true) {
            case ((shouldBarBeColored === true) && ([0,1,2,3,4].includes(props.position))):
                color = 'green';
                break;
            case ((shouldBarBeColored === true) && ([6,7,8,9,10].includes(props.position))):
                color = 'yellow';
                break;
            case ((shouldBarBeColored === true) && ([12,13,14,15,16].includes(props.position))):
                color = 'red';
                break;
        }


        barStyle = { backgroundColor: color };

    }

    return (
        <span className={classes.bar} style={barStyle} key={props.position} />
    );

}

export default OccupancyBar;