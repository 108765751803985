import React, { useState, useEffect } from "react";
import { FormControl, FormLabel, FormControlLabel, FormGroup, Button, MenuItem, makeStyles } from "@material-ui/core";
import { Formik, Field } from "formik";
import classNames from "classnames";
import { TextField, CheckboxWithLabel, Switch } from 'formik-material-ui';
import { useSnackbar } from "notistack";
import { packageTypes } from "__helpers/Utils";
import TimePick from "components/shared/TimePick";
import API from "__helpers/API";
import moment from "moment";

const SkyBar = ({ hotelSettings, hotel }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [hotelOptions, setHotelOptions] = useState([]);

    useEffect(() => {
        const fetchHotels = () => API.get(`/api/receptie/hotel/list`)
            .then((response) => {
                setHotelOptions(response.payload);
            })
            .catch((err) => { });

        fetchHotels();
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                startHour: moment(hotelSettings?.skybar?.startHour || "09:00", "HH:mm"),
                endHour: moment(hotelSettings?.skybar?.endHour || "22:00", "HH:mm"),
                name: hotelSettings?.skybar?.name || "",
                groupWith: hotelSettings?.skybar?.groupWith || false,
                parentHotel: hotelSettings?.skybar?.parentHotel || "",
                description: hotelSettings?.skybar?.description || "",
                associatedPackages: hotelSettings?.skybar?.associatedPackages || [],
                intervalDuration: hotelSettings?.skybar?.intervalDuration || 60,
                intervalGap: hotelSettings?.skybar?.intervalGap || 0,
                capacity: hotelSettings?.skybar?.capacity || 0
            }}
            onSubmit={(values, { setSubmitting }) => {
                API.post("/api/receptie/hotel/settings/skybar", {
                    ...values,
                    hotel,
                    startHour: values.startHour.format("HH:mm"),
                    endHour: values.endHour.format("HH:mm"),
                })
                    .then((response) => {
                        if (response.status) {
                            enqueueSnackbar("Setarile Sky Bar au fost salvate.", { variant: "success" });
                        } else {
                            enqueueSnackbar(response.error, { variant: "error" });
                        }
                        setSubmitting(false)
                    })
                    .catch((err) => {
                        enqueueSnackbar(`Ops, ceva nu a mers bine. ${err.msg}`, { variant: "error" });
                        setSubmitting(false)
                    });
            }}
        >
            {(props) => {
                const {
                    touched,
                    errors,
                    setFieldValue,
                    handleSubmit,
                    submitted,
                    values,
                } = props;
                return (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate
                        className={classes.form}
                    >
                        <h2 className="PageHeadline" style={{ fontSize: "0.75rem", marginBottom: "0.5rem", borderBottom: "1px solid #ccc" }}>Setari SkyBar {hotel}</h2>
                        <FormControl className={classNames(classes.formControl)} fullWidth={true}>
                            <Field
                                component={TextField}
                                type="text"
                                label="Denumire"
                                name="name"
                                variant="outlined"
                                size="small" />
                        </FormControl>
                        <FormControl component="fieldset" className={classNames(classes.formControl)}>
                            <FormLabel component="legend">Pachete asociate {values.showAdvancedOptions}</FormLabel>
                            <FormGroup row>
                                {
                                    packageTypes.map(pck => (
                                        <Field
                                            component={CheckboxWithLabel}
                                            type="checkbox"
                                            name="associatedPackages"
                                            key={pck}
                                            value={pck}
                                            Label={{ label: pck }}
                                        />
                                    ))
                                }
                            </FormGroup>
                        </FormControl>
                        <div style={{ width: "100%" }}>
                            <FormControl className={classNames(classes.formControl)}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            type="checkbox"
                                            component={Switch}
                                            name="groupWith"
                                        />
                                    }
                                    label="Grupeaza impreuna cu sky barul de la"
                                />
                            </FormControl>
                            {
                                values.groupWith && (
                                    <FormControl className={classNames(classes.formControl)} style={{ width: 300 }}>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="parentHotel"
                                            label="Alege Hotel"
                                            select
                                            variant="outlined"
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {
                                                hotelOptions.filter(el => el.label !== hotel).map(el => (
                                                    <MenuItem key={el.value + "" + el.label} value={el.label}>
                                                        {el.label}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Field>
                                    </FormControl>
                                )
                            }
                        </div>

                        {
                            values.groupWith === false && (
                                <React.Fragment>
                                    <FormControl className={classNames(classes.formControl)} fullWidth={true}>
                                        <Field
                                            fullWidth
                                            multiline={true}
                                            rows={10}
                                            component={TextField}
                                            type="text"
                                            label="Descriere"
                                            name="description"
                                            variant="outlined"
                                            size="small"
                                            helperText="se pot folosi taguri HTML: <bold></bold>, <ul></ul>...."
                                        />
                                    </FormControl>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-start"
                                    }}>
                                        <FormControl className={classNames(classes.formControl)}>
                                            <Field
                                                component={TimePick}
                                                label="Ora deschidere"
                                                value={values.startHour}
                                                inputVariant="outlined"
                                                size="small"
                                                ampm={false}
                                                minutesStep={5}
                                                onChange={(val) =>
                                                    setFieldValue("startHour", val)
                                                }
                                                format="HH:mm"
                                                views={["hours", "minutes"]}
                                            />
                                        </FormControl>
                                        <FormControl
                                            className={classNames(classes.formControl)}
                                            error={errors.end && touched.end}

                                        >
                                            <Field
                                                component={TimePick}
                                                label="Ora inchidere"
                                                value={values.endHour}
                                                inputVariant="outlined"
                                                size="small"
                                                ampm={false}
                                                minutesStep={5}
                                                onChange={(val) =>
                                                    setFieldValue("endHour", val)
                                                }
                                                format="HH:mm"
                                                views={["hours", "minutes"]}
                                            />
                                        </FormControl>
                                        <FormControl className={classNames(classes.formControl)}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Capacitate"
                                                name="capacity"
                                                value={values.capacity}
                                                variant="outlined"
                                                size="small"
                                                helperText="numar de rezervari max ce pot fi facute"
                                            />
                                        </FormControl>
                                    </div>
                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-start"
                                    }}>
                                        <FormControl className={classNames(classes.formControl)}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Durata interval"
                                                name="intervalDuration"
                                                value={values.intervalDuration}
                                                variant="outlined"
                                                size="small"
                                                helperText="minute"
                                            />
                                        </FormControl>
                                        <FormControl className={classNames(classes.formControl)}>
                                            <Field
                                                component={TextField}
                                                type="text"
                                                label="Pauza intervale"
                                                name="intervalGap"
                                                value={values.intervalGap}
                                                variant="outlined"
                                                size="small"
                                                helperText="minute" />
                                        </FormControl>
                                    </div>
                                </React.Fragment>
                            )
                        }

                        <div style={{ width: "100%" }}>
                            <Button
                                className={classNames(classes.submitBtn)}
                                type="submit" color="primary" variant="contained" disabled={submitted}>Salveaza</Button>
                        </div>
                    </form >
                )
            }}
        </Formik >
    );
}

const useStyles = makeStyles(theme => ({
    form: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    formControl: {
        margin: theme.spacing(2, 0.25),
        [theme.breakpoints.down("xs")]: {
            minWidth: "100%",
            marginRight: theme.spacing(0),
            marginBottom: theme.spacing(1)
        }
    },
    submitBtn: {
        display: "block",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    }
}))

export default SkyBar;