import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { authenticationService } from "../../__services/authenticationService";
import { BtnCsdm } from "../shared/Presentational";

export default function GuestLayout({ component: Component, ...rest }) {
  const classes = useStyles();
  let history = useHistory();
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!authenticationService.currentUserValue) {
          return <Redirect to={{ pathname: "/guest/login" }} />;
        }
        return (
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ fontWeight: "bolder" }}
                >
                  Complex Hotelier Steaua De Mare
                </Typography>
                <div className={classes.grow} />
                <BtnCsdm
                  style={{ padding: "0.5rem 1rem", fontWeight: "bold" }}
                  onClick={() => {
                    authenticationService.logout();
                    history.push("/");
                  }}
                >
                  Iesire
                </BtnCsdm>
              </Toolbar>
            </AppBar>
            <main className={classes.content}>
              <Toolbar />
              <Container maxWidth="lg">
                <Component {...matchProps} />
              </Container>
            </main>
          </div>
        );
      }}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#06213e",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "#b49151",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
  grow: {
    flexGrow: 1,
  },
}));
