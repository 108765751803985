import React from "react";
import clsx from "clsx";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function TableCellMeal({ children, ...rest }) {
  const classes = useStyles();
  const { percent } = rest;
  return (
    <TableCell
      key={Math.floor(Math.random() * 9999999)}
      align="center"
      className={clsx(classes.headCell, {
        ["OkBg"]: percent <= 50,
        ["AlmostOkBg"]: percent > 50 && percent <= 75,
        ["WarningBg"]: percent > 75 && percent <= 99,
        ["FullBg"]: percent > 99,
      })}
      {...rest}
    >
      {children}
    </TableCell>
  );
}

const useStyles = makeStyles((theme) => ({
  headCell: {
    fontSize: theme.typography.pxToRem(10),
    textTransform: "uppercase",
    fontWeight: "bold",
    border: "1px solid #ccc",
    padding: theme.spacing(0.5, 1),
    lineHeight: 1.5,
  },
}));
