import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SearchClient from "./__components/SearchClient";
import ClientInfo from "./__components/ClientInfo";
import CustomerPackageInfo from "./__components/CustomerPackageInfo";
import MealPlanner from "./__components/MealPlanner";
import Intervals from "../shared/Intervals";
import SpaIntervals from "components/shared/PoolIntervals";

export default function CheckIn() {
  const classes = useStyles();

  const [customer, setCustomer] = useState({});

  const handleUpdateCustomer = (customer) => {
    setCustomer(customer);
  };

  return (
    <Paper className={classes.paper}>
      {Object.entries(customer).length > 0 ? (
        <React.Fragment>
          <ClientInfo
            customer={customer}
            updateCustomer={handleUpdateCustomer}
          />
          <CustomerPackageInfo
            customer={customer}
            updateCustomer={handleUpdateCustomer}
          />
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panelTableIntervals-content"
              id="panelTableIntervals-header"
            >
              <Typography className={classes.heading}>
                Intervale de masa
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Intervals
                customerIdentifier={customer.checkInNo}
                customer={customer}
                apiEndpointFetchIntervals={"/api/receptie/customer/intervals"}
                apiEndpointChangeIntervals={
                  "/api/receptie/customer/change-interval-for-all-meals"
                }
                updateCustomer={handleUpdateCustomer}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panelPoolIntervals-content"
              id="panelPoolIntervals-header"
            >
              <Typography className={classes.heading}>
                Piscina
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <SpaIntervals
                isDisplayedInAdmin={true}
                customerIdentifier={customer.identifier}
                customer={customer}
                apiEndpointFetchPoolIntervals={"/api/receptie/pool/get/customer/intervals"}
                apiEndpointChangePoolIntervals={"/api/receptie/pool/customer/change-pool-interval"}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {Object.entries(customer.allocations).length > 0 && (
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panelMeals-content"
                id="panelMeals-header"
              >
                <Typography className={classes.heading}>
                  Planificator
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <MealPlanner
                  hotel={customer.hotel}
                  allocations={customer.allocations}
                  objectiveLoad={customer.objectiveLoad}
                  customerIdentifier={customer.checkInNo}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </React.Fragment>
      ) : (
        <SearchClient updateCustomer={handleUpdateCustomer} />
      )}
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
}));
