import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Color from "color";
import logoSdm from "./../../img/complex-logo.png";

export const BtnCsdm = withStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #b49151 30%, #d3b070 90%)",
    color: theme.palette.common.white,
    padding: theme.spacing(2, 1.5),
    margin: theme.spacing(2, 0),
  },
}))(Button);

export const GridCsdm = withStyles((theme) => ({
  root: {
    backgroundImage: "url(https://source.unsplash.com/collection/8958441)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}))(Grid);

export function CsdmBox({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.holder}>
      <img style={{ maxWidth: "90%" }} src={logoSdm} alt="logoSdm" />
      {children}
    </div>
  );
}

export function BackdropCsdm(props) {
  const classes = useStyles();
  return (
    <Backdrop open={props.open} className={classes.backdrop}>
      <CircularProgress />
    </Backdrop>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  holder: {
    zIndex: 100,
    padding: theme.spacing(1, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Color("#06213e").alpha(0.9).string(),
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/collection/8958441)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    height: "calc(100vh - 40px)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
