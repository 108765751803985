import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import orange from "@material-ui/core/colors/orange";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { BtnCsdm } from "components/shared/Presentational";
import { useSnackbar } from "notistack";
import API from "__helpers/API";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: orange[50],
  },
}));

const mealTypes = ["MD", "PRANZ", "CINA"];

export default function Intervals(props) {
  const customerIdentifier = props.customerIdentifier;
  const [loading, setLoading] = useState(false);
  const [intervals, setIntervals] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedInterval, setSelectedInterval] = useState(
    props.customer.allocatedInterval
  );
  const classes = useStyles();
  const handleChange = (event) => {
    setSelectedInterval(event.target.value);
  };

  const handleIntervalSubmit = () => {
    if (selectedInterval === "") {
      enqueueSnackbar("Nu ai selectat intervalul.", { variant: "error" });

      return;
    }
    setLoading(true);
    API.post(props.apiEndpointChangeIntervals, {
      identifier: customerIdentifier,
      interval: selectedInterval,
    })
      .then((response) => {
        setLoading(false);
        if (response.status) {
          enqueueSnackbar("Intervalul a fost schimbat", { variant: "success" });
          if (props.updateCustomer) {
            props.updateCustomer(response.payload);
          }
        } else {
          setSelectedInterval("");
          enqueueSnackbar(response.error, { variant: "error" });
        }
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("Eroare de comunicare", { variant: "error" });
      });
  };

  useEffect(() => {
    API.post(props.apiEndpointFetchIntervals, {
      identifier: customerIdentifier,
    })
      .then((response) => {
        if (response.status) {
          setIntervals(response.payload);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Eroare de comunicare", { variant: "error" });
      });
  }, []);

  useEffect(() => {
    setSelectedInterval(props.customer.allocatedInterval);
  }, [props.customer.allocatedInterval]);

  return (
    <Grid container spacing={3} justify="center">
      {intervals.length > 0 && (
        <Grid item xs sm={8}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  {props.customer.objective}
                </TableCell>
                {intervals.length > 0 &&
                  mealTypes.map((meal, idx) => {
                    if (Object.keys(intervals[0]).includes(meal)) {
                      return (
                        <TableCell
                          align="center"
                          key={idx}
                          style={{ fontWeight: "bold" }}
                        >
                          {meal}
                        </TableCell>
                      );
                    }
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {intervals.length > 0 &&
                intervals.map((el, idx) => {
                  let label = ` Interval ${idx + 1}: `;
                  return (
                    <TableRow
                      key={idx}
                      className={clsx({
                        [classes.selected]: idx.toString() === selectedInterval,
                      })}
                    >
                      <TableCell style={{ padding: "1px" }}>
                        <FormControlLabel
                          value={idx}
                          control={<Radio />}
                          label={
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                textTransform: "uppercase",
                                fontSize: "0.75rem",
                              }}
                            >
                              {label}
                            </Typography>
                          }
                          name="selectedInterval"
                          onChange={handleChange}
                          checked={parseInt(selectedInterval) === idx}
                        />
                      </TableCell>
                      {mealTypes.map((meal, mealIdx) => {
                        if (Object.keys(el).includes(meal)) {
                          return (
                            <TableCell
                              align="center"
                              key={mealIdx}
                              style={{ padding: "1px" }}
                            >
                              {el[meal].intvStart}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <BtnCsdm fullWidth disabled={loading} onClick={handleIntervalSubmit}>
            Salveaza selectia
          </BtnCsdm>
        </Grid>
      )}

      {intervals.length <= 0 && (
        <Grid item xs sm={12}>
          <Alert severity="error">
            <AlertTitle>Atentie</AlertTitle>
            <strong> Nu exista nici un tip de masa achizitionat</strong>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
