import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Button, ButtonGroup, Badge, Tooltip, Typography, CircularProgress } from '@material-ui/core';
import { BeachAccess } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { blueGrey, green, yellow } from '@material-ui/core/colors';
import { useMediaQuery } from 'react-responsive'

const NestedGrid = ({ reserveFunction, matrix, changes, releaseFunction, changeBeach, beach, loading, stopLoading }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const classes = useStyles();
  const [grids, setGrids] = useState([]);
  const [firstBeachSelected, setFirstBeachSelected] = useState(true);
  let FIRST_BEACH = 9877;
  let SECOND_BEACH = 9876;

  useEffect(() => {
    if (beach === FIRST_BEACH) {
      setFirstBeachSelected(true);
    } else {
      setFirstBeachSelected(false);
    }
  }, [beach]);

  useEffect(() => {
    var newGrids = [];
    var arrayOfColumns = [];
    var modifiedData = [];

    let MAX_ALLOWED_ROWS;
    if (isDesktopOrLaptop) {
      MAX_ALLOWED_ROWS = 16;
    } else {
      MAX_ALLOWED_ROWS = 8;
    }

    const GRID_OFFSET = 1;

    for (let l = 0; l < matrix.length; l++) {
      if (matrix[l]) {
        arrayOfColumns.push(matrix[l].col);
        matrix[l].indexGrid = Math.ceil(matrix[l].col / MAX_ALLOWED_ROWS) - GRID_OFFSET;
        modifiedData.push(matrix[l])
      };
    }

    const maxColumns = Math.max(...arrayOfColumns);
    for (let o = 0; o < Math.ceil(maxColumns / MAX_ALLOWED_ROWS); o++) {
      newGrids.push(o);
    }

    for (let i = 0; i < newGrids.length; i++) {
      if (!newGrids[modifiedData[i].indexGrid]) {
        newGrids[modifiedData[i].indexGrid] = [];
      }
      var tempArray = [];
      for (let j = 0; j < modifiedData.length; j++) {
        if (!tempArray[modifiedData[j].row - 1]) {
          tempArray[modifiedData[j].row - 1] = [];
        }
        if (i === modifiedData[j].indexGrid) {
          tempArray[modifiedData[j].row - 1].push(modifiedData[j])
        }
      }
      newGrids[i] = tempArray;
    }

    for (let i = 0; i < newGrids.length; i++) {
      for (let j = 0; j < newGrids[i].length; j++) {
        if (newGrids[i][j].length < MAX_ALLOWED_ROWS) {
          const ConstLength = MAX_ALLOWED_ROWS - newGrids[i][j].length;
          for (let o = 0; o < ConstLength; o++) {
            newGrids[i][j].push({ no: o, status: 'none' });
          }
        }
      }
    }

    setGrids(newGrids);
    if (newGrids.length) {
      stopLoading();
    }
  }, [changes]);


  const FreePlace = ({ placeInfo }) => {
    return (
      <React.Fragment>
        {(() => {
          switch (placeInfo.type) {
            case 4: return <Button onClick={() => reserveFunction({ col: placeInfo.col, row: placeInfo.row }, placeInfo.identifier, placeInfo.description)} className={classes.placeType4}>
              <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={placeInfo.type}>
                <BeachAccess />
              </Badge>
            </Button>
            case 3: return <Button onClick={() => reserveFunction({ col: placeInfo.col, row: placeInfo.row }, placeInfo.identifier, placeInfo.description)} className={classes.placeType3}>
              <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={placeInfo.type}>
                <BeachAccess />
              </Badge>
            </Button>
            case 2: return <Button onClick={() => reserveFunction({ col: placeInfo.col, row: placeInfo.row }, placeInfo.identifier, placeInfo.description)} className={classes.placeType2}>
              <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={placeInfo.type}>
                <BeachAccess />
              </Badge>
            </Button>
            case null: return <Button onClick={() => reserveFunction({ col: placeInfo.col, row: placeInfo.row }, placeInfo.identifier, placeInfo.description)} className={classes.place}>{placeInfo.no}</Button>
            default: return <Button onClick={() => reserveFunction({ col: placeInfo.col, row: placeInfo.row }, placeInfo.identifier, placeInfo.description)} className={classes.place}>{placeInfo.no}</Button>
          }
        })()}
      </React.Fragment>
    );
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#faf7ed',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  function FormRow({ rowGrid }) {

    return (
      <React.Fragment>

        {rowGrid && rowGrid.map((place, index) =>
          <Grid key={index} item xs>
            {(() => {
              switch (place.status) {
                case "free": return <FreePlace placeInfo={place}></FreePlace>
                case "unavailable": return <Button className={classes.placeUnavailable} disabled>
                  <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={place.type}>
                    <BeachAccess />
                  </Badge></Button>
                case "reserved": return <Button className={classes.placeReserved} onClick={() => releaseFunction(place.no, place.identifier)}><Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={place.type}>
                  <BeachAccess />
                </Badge></Button>
                case "none": return <Button className={classes.placeNone} disabled>
                  <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={place.type}>
                    <BeachAccess />
                  </Badge>
                </Button>
                default: return <Button className={classes.placeNone}>
                  <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent={place.type}>
                    <BeachAccess />
                  </Badge>
                </Button>
              }
            })()}
          </Grid>
        )}

      </React.Fragment>
    );
  }
  return (
    <div>
      <span className="gridPlaja" >

        <div className={classes.rootReservation}>
          {loading ? <span className='centered-flex loadingGrid'><CircularProgress /></span>
            : grids.map((place, indexGrid) =>
              <Grid key={indexGrid} container className={classes.gridContainer} spacing={1}>
                {place.map((row, indexRow) =>
                  <Grid key={indexRow} container item className={classes.gridRow} xs={12} >
                    <FormRow container rowGrid={row} item xs={12} />
                  </Grid>
                )}
              </Grid>
            )
          }
        </div>
      </span>
      <span className={classes.beachChoice}>
        <ButtonGroup disableElevation variant="contained" color="primary">
          {firstBeachSelected ?
            <Button className={classes.buttonActive} onClick={() => { changeBeach(FIRST_BEACH); setFirstBeachSelected(true); }} >Plaja mica - Sector 1  {isDesktopOrLaptop ? '(in stanga digului)' : ""}</Button>
            :
            <Button className={classes.button} onClick={() => { changeBeach(FIRST_BEACH); setFirstBeachSelected(true); }}>Plaja mica - Sector 1 {isDesktopOrLaptop ? '(in stanga digului)' : ""}</Button>
          }
          {!firstBeachSelected ?
            <Button className={classes.buttonActive} onClick={() => { changeBeach(SECOND_BEACH); setFirstBeachSelected(false); }}>Plaja Mare - Sector 2 {isDesktopOrLaptop ? '(in dreapta digului)' : ""}</Button>
            :
            <Button className={classes.button} onClick={() => { changeBeach(SECOND_BEACH); setFirstBeachSelected(false); }} >Plaja Mare - Sector 2 {isDesktopOrLaptop ? '(in dreapta digului)' : ""}</Button>
          }
        </ButtonGroup>
        <HtmlTooltip enterTouchDelay={0} leaveTouchDelay={5000}
          title={
            <React.Fragment>
              <div className="legend-info">
                <Typography color="inherit">Legenda</Typography>
                <div className={classes.legend}>
                  <span className={classes.legendRow}>
                    <span className={classes.legendPlace}>
                      <FreePlace placeInfo={{ no: 1, type: 4 }}></FreePlace>
                    </span>
                    <Typography className={classes.legendText} >-umbrela libera</Typography>
                  </span>
                  <span className={classes.legendRow}>
                    <span className={classes.legendPlace}>
                      <FreePlace placeInfo={{ no: 1, type: 3 }}></FreePlace>
                    </span>
                    <Typography className={classes.legendText} >-umbrela de stuff libera</Typography>
                  </span>
                  <span className={classes.legendRow}>
                    <span className={classes.legendPlace}>
                      <FreePlace placeInfo={{ no: 1, type: 2 }}></FreePlace>
                    </span>
                    <Typography className={classes.legendText} > -umbrela de ratan libera</Typography>
                  </span>
                  <span className={classes.legendRow}>
                    <span className={classes.legendPlace}>
                      <Button className={classes.placeReserved} ><Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent="4">
                        <BeachAccess />
                      </Badge></Button>
                    </span>
                    <Typography className={classes.legendText} >-umbrela patrata rezervata</Typography>
                  </span>
                  <span className={classes.legendRow}>
                    <span className={classes.legendPlace}>
                      <Button className={classes.placeUnavailable} disabled><Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} color="error" overlap="rectangular" badgeContent="4">
                        <BeachAccess />
                      </Badge></Button>
                    </span>
                    <Typography className={classes.legendText} >-umbrela patrata indisponibila</Typography>
                  </span>
                </div>
              </div>
            </React.Fragment>
          }
        >
          <InfoIcon className={classes.legendIcon} />
        </HtmlTooltip>
      </span>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  rootReservation: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'scroll',
    maxHeight: '50%',
    position: 'fixed',
    bottom: '50px',
    background: '#faf7ed'
  },
  beachChoice: {
    width: '100%',
    position: 'fixed',
    bottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  gridContainer: {
    minWidth: '100%',
    margin: '0',
  },
  gridRow: {
    padding: '0px !important',
    margin: '0',
  },
  place: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0',
    background: green[400],
    marginBottom: '0em',
    minWidth: '100%',
    border: '0.5px solid',
    borderColor: green[600]
  },
  placeType4: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0',
    background: green[400],
    marginBottom: '0em',
    minWidth: '100%',
    border: '0.5px solid',
    borderColor: green[600]
  },
  placeType3: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0',
    background: green[300],
    marginBottom: '0em',
    minWidth: '100%',
    border: '0.5px solid',
    borderColor: green[600]
  },
  placeType2: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '0',
    background: green[100],
    marginBottom: '0em',
    minWidth: '100%',
    border: '0.5px solid',
    borderColor: green[600]
  },
  placeUnavailable: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'white',
    margin: '0',
    background: blueGrey[50],
    marginBottom: '0em',
    minWidth: '100%',
    border: '0.5px solid',
    borderColor: blueGrey[200]
  },
  placeReserved: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'black',
    margin: '0',
    background: yellow[400],
    marginBottom: '0em',
    minWidth: '100%',
    border: '0.5px solid',
    borderColor: yellow[600]
  },
  placeNone: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'white',
    margin: '0',
    background: 'white',
    minWidth: '100%',
    opacity: 0
  },
  button: {
    background: "linear-gradient(45deg, #b49151 30%, #d3b070 90%)",
    color: theme.palette.common.white,
    borderColor: 'black !important ',
    fontWeight: 'bold',
    lineHeight: 1.2
  },
  buttonActive: {
    background: "linear-gradient(45deg, #927540 30%, #a78c59 90%)",
    color: theme.palette.common.white,
    borderColor: 'black !important ',
    fontWeight: 'bold',
    lineHeight: 1.2
  }, legendIcon: {
    position: 'fixed',
    right: '12px'
  }, legend: {
    display: 'flex',
    flexDirection: 'column'
  }, legendRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0.5em'
  }, legendPlace: {
    width: '20px'
  }, legendText: {
    marginLeft: '35px'
  }
}));

export default NestedGrid;
