import React, { useState } from "react";
import {
    Typography, Button,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@material-ui/core";
import parse from 'html-react-parser';

const CustomerPackageInfo = ({ customer, classes }) => {
    const [openPackageModal, setOpenPackageModal] = useState(false);

    return (
        <div>
            {
                customer.package && (
                    <div style={{ textAlign: "center", border: "1px dashed #b49151", padding: "1rem 0.25rem" }}>
                        <Typography
                            variant="body1"
                            component="h1"
                            className={`${classes.infoText} ${classes.spacer}`}
                            align="center"
                            style={{ marginTop: "0" }}
                        >
                            Pachetul aferent sejurului tau este <span style={{ fontWeight: "bold", fontSize: "1.35rem", textTransform: "uppercase" }}>{customer.package.name}</span> {' '}
                        </Typography>
                        <Button
                            onClick={() => setOpenPackageModal(true)}
                            style={{ padding: "0.25rem", backgroundColor: '#b49151', color: 'white' }} size="small">poti vedea ce cuprinde apasand aici</Button>
                        <Dialog
                            maxWidth="md"
                            open={openPackageModal}
                            onClose={() => setOpenPackageModal(false)}
                            aria-labelledby="packageInfo-title"
                            aria-describedby="packageInfo-description"
                        >

                            <DialogTitle style={{ backgroundColor: '#b49151', color: "white" }}>
                                Descrierea pachetului {' '}
                                <span style={{ fontWeight: "bold", fontSize: "1.35rem", textTransform: "uppercase" }}>{`${customer.package.name}`}</span>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {parse(customer.package.description)}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenPackageModal(false)} color="primary" autoFocus>
                                    Inchide
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )
            }
        </div>
    );
}

export default CustomerPackageInfo;