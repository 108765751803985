import OccupancyBar from "components/shared/Occupancy/OccupancyBar";
import React from "react";

const barNumbers = 17;

const OccupancyGroup = props => {
    let barsCounter = Array(barNumbers).fill(1);
    barsCounter[5] = 0;
    barsCounter[11] = 0;

    return (
        <div class="tooltip-container" >
            {
                barsCounter.map((value, index) => (
                    <OccupancyBar
                        position={index}
                        key={index}
                        value={props.value}
                        totalBars={barNumbers}
                        isEmptyBar={value}
                    />
                ))
            }
            <span class="tooltip">Grad de ocupare</span>
        </div>
    )

}

export default OccupancyGroup;