const rules = {
    144: {
        static: ["customer:checkin", "reports:list", "intervals:list", "spa:list", "hotel:settings"]
        //"skybar:appointments"
    },
    233: {
        static: ["customer:checkin", "reports:list", "skybar:appointments"]
    },
    377: {
        static: ["spa:programari"]
    }
}

export default rules;