import React, { useEffect, useState } from "react";
import { Grid, Paper, Tabs, Tab } from "@material-ui/core";
import { useSnackbar } from "notistack";
import API from "__helpers/API";

import GlobalSettings from "components/admin/__components/HotelSettings/GlobalSettings";
import PackagesMap from "components/admin/__components/HotelSettings/PackagesMap";
import SkyBar from "components/admin/__components/HotelSettings/SkyBar";
import Accordion from "components/shared/Accordion";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}

export default function HotelSettings() {
  const { enqueueSnackbar } = useSnackbar();
  const [hotelsSettings, setHotelsSettings] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (_, tab) => {
    setCurrentTab(tab);
  }

  useEffect(() => {
    API.post("/api/receptie/hotel/list-settings")
      .then((response) => {
        setHotelsSettings(response);
      })
      .catch((err) => {
        enqueueSnackbar(err.msg, { variant: "error" });
      });
  }, [enqueueSnackbar]);

  return (
    <React.Fragment >
      <Paper elevation={2} className="ContainerSpacer">
        <Grid container direction="row">
          <Grid item xs={12}>
            <h1 className="PageHeadline">Setari Globale Hoteluri</h1>
          </Grid>
          <Grid item xs={12}>
            <Tabs value={currentTab} onChange={handleTabChange}>
              {
                Object.keys(hotelsSettings).map((hotel, hotelIdx) => (
                  <Tab label={`Setari ${hotel}`} key={hotelIdx} />
                ))
              }
            </Tabs>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className="ContainerSpacer">

        <TabPanel value={currentTab} index={0}>
          <Accordion title="Setari globale">
            <GlobalSettings hotelsSettings={hotelsSettings} hotel="Aqvatonic" />
          </Accordion>
          <Accordion title="Diagrama camere">
            <PackagesMap hotel="Aqvatonic" hotelSettings={hotelsSettings["Aqvatonic"]} />
          </Accordion>
          <Accordion title="Sky Bar">
            <SkyBar hotel="Aqvatonic" hotelSettings={hotelsSettings["Aqvatonic"]} />
          </Accordion>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <Accordion title="Setari globale">
            <GlobalSettings hotelsSettings={hotelsSettings} hotel="Meduza" />
          </Accordion>
          <Accordion title="Diagrama camere">
            <PackagesMap hotel="Meduza" hotelSettings={hotelsSettings["Meduza"]} />
          </Accordion>
          <Accordion title="Sky Bar">
            <SkyBar hotel="Meduza" hotelSettings={hotelsSettings["Meduza"]} />
          </Accordion>
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <Accordion title="Setari globale">
            <GlobalSettings hotelsSettings={hotelsSettings} hotel="Delfinul" />
          </Accordion>
          <Accordion title="Diagrama camere">
            <PackagesMap hotel="Delfinul" hotelSettings={hotelsSettings["Delfinul"]} />
          </Accordion>
          <Accordion title="Sky Bar">
            <SkyBar hotel="Delfinul" hotelSettings={hotelsSettings["Delfinul"]} />
          </Accordion>
        </TabPanel>

      </Paper>
    </React.Fragment >
  );
}
