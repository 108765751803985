import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import CustomersOnInterval from "./CustomersOnInterval";
import TableRowMealTypes from "./TableRowMealTypes";
import TableCellMeal from "./TableCellMeal";
import API from "./../../../__helpers/API";
import { mealTypes } from "./../../../__helpers/Utils";

export default function ReportIntervalLoad(props) {
  const selectedDate = props.selectedDate;
  const showPercent = props.showPercent;
  const classes = useStyles();
  const [customersDialogOpen, setCustomersDialogOpen] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [objectiveLoads, setObjectiveLoads] = useState({});

  useEffect(() => {
    fetchObjectiveLoad();
  }, [selectedDate]);

  const fetchObjectiveLoad = () => {
    API.post("/api/receptie/reports/today-objective-loads", {
      selectedDate: selectedDate.format("Y-MM-DD"),
    })
      .then((response) => {
        setObjectiveLoads(response);
      })
      .catch((err) => {});
  };

  const handleCustomersDialogOpen = (interval) => {
    setSelectedInterval(interval.interval);
    setCustomersDialogOpen(true);
  };

  const handleCustomersDialogClose = () => {
    setCustomersDialogOpen(false);
  };

  return (
    <React.Fragment>
      {Object.keys(objectiveLoads).map((obj, objIdx) => (
        <Paper
          elevation={2}
          className="Paper"
          style={{ marginBottom: "1rem" }}
          key={objIdx * 999999999}
        >
          <Table>
            <TableHead>
              <TableRowMealTypes />
              <TableRow>
                <TableCell
                  align="center"
                  style={{ maxWidth: "150px" }}
                  className={`${classes.headCell} ${classes.objective}`}
                >
                  {obj}
                </TableCell>
                {mealTypes.map((meal, mealIdx) => {
                  return objectiveLoads[obj][meal]["intervals"].map(
                    (intv, idxIntv) => (
                      <TableCell
                        key={
                          idxIntv +
                          mealIdx +
                          Math.floor(Math.random() * 9999999999)
                        }
                        align="center"
                        className={classes.headCell}
                      >
                        <p
                          style={{
                            fontSize: "9px",
                            color: "#9e9e9e",
                            margin: "2px",
                          }}
                        >
                          {intv.startTime}
                        </p>
                        <p
                          style={{
                            fontSize: "9px",
                            color: "#9e9e9e",
                            margin: "2px",
                          }}
                        >
                          {intv.endTime}
                        </p>

                        <p>{intv.eventName}</p>
                        <IconButton
                          size="small"
                          onClick={() => handleCustomersDialogOpen(intv)}
                          style={{
                            display: "block",
                            margin: "0 auto",
                            color: "#ccc",
                          }}
                          color="primary"
                          title="Refresh data"
                        >
                          <FaceIcon />
                        </IconButton>
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.headCell}>Incarcare</TableCell>
                {mealTypes.map((meal, mealIdx) => (
                  <React.Fragment
                    key={mealIdx + Math.floor(Math.random() * 9999999)}
                  >
                    {objectiveLoads[obj][meal]["intervals"].map(
                      (intv, idxIntv) => (
                        <TableCellMeal
                          key={idxIntv + Math.floor(Math.random() * 9999999)}
                          percent={intv.occupancyPercent}
                        >
                          {showPercent === true
                            ? `${intv.occupancyPercent}%`
                            : `${intv.load["occupancy"]} / ${intv.load["capacity"]}`}
                        </TableCellMeal>
                      )
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={classes.headCell}>Total</TableCell>
                {mealTypes.map((meal, mealIdx) => (
                  <TableCellMeal
                    colSpan={4}
                    key={mealIdx + Math.floor(Math.random() * 9999999)}
                    percent={
                      objectiveLoads[obj][meal]["total"]["occupancyPercent"]
                    }
                  >
                    {showPercent === true
                      ? `${objectiveLoads[obj][meal]["total"]["occupancyPercent"]}%`
                      : `${objectiveLoads[obj][meal]["total"]["occupancy"]} / ${objectiveLoads[obj][meal]["total"]["capacity"]}`}
                  </TableCellMeal>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      ))}

      <CustomersOnInterval
        interval={selectedInterval}
        selectedDate={selectedDate}
        isOpen={customersDialogOpen}
        updateIsOpen={handleCustomersDialogClose}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  head: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  headCell: {
    fontSize: theme.typography.pxToRem(10),
    textTransform: "uppercase",
    fontWeight: "bold",
    border: "1px solid #ccc",
    padding: theme.spacing(0.5, 1),
    lineHeight: 1.5,
  },
  odd: {
    backgroundColor: theme.palette.grey[100],
  },
  objective: {
    backgroundColor: "#06213e",
    color: "white",
  },
}));
