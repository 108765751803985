import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid, Paper } from "@material-ui/core";
import API from "__helpers/API";
import axios from 'axios';
import { useSnackbar } from "notistack";
import SectorsImagesComponent from "components/admin/__components/SectorsImagesComponent";
import { authenticationService } from "__services/authenticationService";

const SectorsImages = () => {

    const [sectorsData, setSectorsData] = useState([]);
    const [sectorsImagesData, setSectorsImagesData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedSector, setSelectedSector] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [sectorImageInputValue, setSectorImageInputValue] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const updateSectorsData = (requestData) => {
        setSectorsData(requestData.sectors);
        setSectorsImagesData(requestData.sectorsImages);
    }


    const handleChangeSectorsSelector = (event) => {
        setSelectedSector(event.target.value);
    }

    const handleSuccessSectorImageUpdate = data => {
        let sectorsImageDataTmp = sectorsImagesData;
        sectorsImageDataTmp[selectedSector] = data.file;
        setSectorsImagesData(sectorsImageDataTmp);
        setLoading(false);
        setInputValue('');
    }

    const handleSuccessSectorImageChange = data => {
        let sectorsImageDataTmp = sectorsImagesData;
        sectorsImageDataTmp[selectedSector] = data.file;
        setSectorsImagesData(sectorsImageDataTmp);
        setLoading(false);
        setSectorImageInputValue('');
    }

    const handleErrorSectorImageUpdate = data => {
        setLoading(false);
        setInputValue('');
        const errorMessage = data.error ?? "Eroare de comunicare";
        enqueueSnackbar(errorMessage, { variant: "error" });
    }

    const handleChangeFileInput = (event) => {

        setLoading(true);

        let data = new FormData();
        data.append('file', event.target.files[0]);
        data.append('sector', selectedSector);

        axios.post(
            process.env.REACT_APP_API_ENDPOINT+'/api/receptie/sunbeds/set/sector/image',
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authenticationService.currentUserValue.token}`
                }
            }
        )
        .then(response => {

            response.data.status ?
                handleSuccessSectorImageUpdate(response.data.payload) :
                handleErrorSectorImageUpdate(response.data);
        })
        .catch(response => {
            handleErrorSectorImageUpdate(response.data);
        });
    }

    const handleChangeSectorImage = event => {

        setLoading(true);

        let data = new FormData();
        data.append('file', event.target.files[0]);
        data.append('sector', selectedSector);
        data.append('existingImage', sectorsImagesData[selectedSector]);

        axios.post(
            process.env.REACT_APP_API_ENDPOINT+'/api/receptie/sunbeds/update/sector/image',
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authenticationService.currentUserValue.token}`
                }
            }
        )
        .then(response => {

            response.data.status ?
                handleSuccessSectorImageChange(response.data.payload) :
                handleErrorSectorImageUpdate(response.data);
        })
        .catch(response => {
            handleErrorSectorImageUpdate(response.data);
        });

    }

    useEffect(() => {

        API.get("/api/receptie/sunbeds/get/sectors/images", {})
        .then((response) => {
            response.status ?
                updateSectorsData(response.payload) :
                setErrorMessage(response.error);
        })
        .catch((err) => {
            setErrorMessage("Eroare de comunicare");
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

    },[]);

    return (

        <Paper elevation={2} className="ContainerSpacer">
            <Grid container spacing={2} >
                {(errorMessage.length > 0) && (
                    <Grid item xs sm={12} >
                        <Alert severity="error" style={{ display: 'flex', justifyContent: 'center', }} >
                            <AlertTitle>Atentie</AlertTitle>
                            <strong> {errorMessage} </strong>
                        </Alert>
                    </Grid>
                )}

                {(errorMessage.length === 0) && (
                    <SectorsImagesComponent
                        sectorsData = {sectorsData}
                        handleChangeFileInput = {handleChangeFileInput}
                        loading = {loading}
                        handleChangeSectorsSelector = {handleChangeSectorsSelector}
                        sectorImage = { sectorsImagesData[selectedSector] ?? '' }
                        selectedSector = { selectedSector }
                        inputValue = { inputValue }
                        handleChangeSectorImage = {handleChangeSectorImage}
                        sectorImageInputValue = {sectorImageInputValue}
                    />
                )}
            </Grid>
        </Paper>

    );

}

export default SectorsImages;