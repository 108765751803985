import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MealSelectableCell } from "../../shared/mealCells";
import green from "@material-ui/core/colors/green";
import yellow from "@material-ui/core/colors/yellow";
import clsx from "clsx";

export default function MealSelectable(props) {
  const [occupancy] = useState(props.occupancy);
  const classes = useStyles();

  return (
    <MealSelectableCell
      align="center"
      className={clsx("InfoCell", {
        [classes.ok]: occupancy <= 50,
        [classes.almostOk]: occupancy > 50 && occupancy <= 75,
        [classes.warning]: occupancy > 75 && occupancy <= 99,
        [classes.full]: occupancy > 99,
      })}
    >
      <span className="ShowOnHover">{occupancy}%</span>
    </MealSelectableCell>
  );
}

const useStyles = makeStyles((theme) => ({
  ok: {
    backgroundColor: green[100],
    "&:hover": {
      backgroundColor: green[200],
    },
  },
  almostOk: {
    backgroundColor: green[300],
    "&:hover": {
      backgroundColor: green[400],
    },
  },
  warning: {
    backgroundColor: yellow[500],
    "&:hover": {
      backgroundColor: yellow[600],
    },
  },
  full: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}));
