import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PoolIntervals from "components/shared/PoolIntervals";

const PoolPlanner = ({ customer, classes }) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          component="h1"
          className={`${classes.infoText} ${classes.spacer}`}
          align="center"
        >
          Ca urmare a
          normelor de distantare COVID-19, impuse de autoritati si pentru
          siguranta dumneavoastra, am reorganizat accesul la bazin si zona sport.
        </Typography>
        <Typography
          variant="body1"
          component="h1"
          className={`${classes.spacer} ${classes.infoTextSpecial}`}
          align="center"
        >
          <span style={{ display: "block" }}>
            Accesul in zona bazinului se face pe baza unei programări numai pentru ziua in curs.
          </span>{" "}
          Accesul este permis in primele 30 de minute după ora selectata. Poți  reveni oricând in aceasta secțiune sa modifici ora dar te rugam sa ai în vedere ca turnicheții se vor actualiza după 5 minute.
          {" "}
          Din respect pentru ceilalti turisti si pentru a evita aglomeratia,
          <span style={{ fontWeight: "bold" }}>
            te rugam sa nu depășești  cele 2 ore alocate.
          </span>
        </Typography>
        <Typography
          variant="body1"
          component="h1"
          align="center"
          className={classes.infoText}
        >
          Te rugam sa selectezi intervalul dorit din optiunile de mai jos.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PoolIntervals
          isDisplayedInAdmin={false}
          customerIdentifier={customer.identifier}
          customer={customer}
          apiEndpointFetchPoolIntervals={"/api/guest/customer/pool-intervals"}
          apiEndpointChangePoolIntervals={"/api/guest/customer/change-customer-pool-interval"}
        />
      </Grid>
    </React.Fragment>
  );
}

export default PoolPlanner;