import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

export const MealNotAvailableCell = withStyles((theme) => ({
  body: {
    backgroundColor: grey[300],
    color: grey[500],
    fontSize: 10,
    fontStyle: "italic",
  },
}))(TableCell);

export const MealSelectedCell = withStyles((theme) => ({
  body: {
    backgroundColor: green[500],
    color: theme.palette.text.primary,
    fontSize: 10,
    fontStyle: "italic",
  },
}))(TableCell);

export const MealSelectableCell = withStyles((theme) => ({
  body: {
    backgroundColor: green[100],
    color: theme.palette.text.primary,
    fontSize: 10,
    fontStyle: "italic",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: green[300],
    },
  },
}))(TableCell);
