import React, { useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import MultipleFilesUploader from "components/admin/__components/MultipleFilesUploader";
import { authenticationService } from "__services/authenticationService";
import axios from 'axios';
import { useSnackbar } from "notistack";

const Regulations = (props) => {

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const handleErrorSectorImageUpdate = data => {
        setSelectedFiles([]);
        setLoading(false);
        const errorMessage = data.error.message ?? "Eroare de comunicare.";
        enqueueSnackbar(errorMessage, { variant: "error" });
    }

    const handleSuccessSectorImageUpdate = data => {

        setSelectedFiles([]);
        setLoading(false);
        enqueueSnackbar(data.message, { variant: "success" });
    }

    const handleSaveSelectedImages = event => {

        setLoading(true);
        let data = new FormData();

        for (let index in selectedFiles) {
            data.append('file-'+index, selectedFiles[index]);
        }

        axios.post(
            process.env.REACT_APP_API_ENDPOINT+'/api/gui/set/rules/setting',
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authenticationService.currentUserValue.token}`
                }
            }
        )
        .then(response => {

            response.data.status ?
                handleSuccessSectorImageUpdate(response.data.payload) :
                handleErrorSectorImageUpdate(response.data);
        })
        .catch(response => {
            handleErrorSectorImageUpdate(response.data);
        });

        setSelectedFiles([]);
    }



    const dropEventHandler = selectedFiles => {
        setSelectedFiles(selectedFiles);
        setLoading((selectedFiles.length === 0))
    }

    return (
        <Paper elevation={2} className="ContainerSpacer">
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <h1 className="PageHeadline">Setare Regulament Sejur</h1>
                </Grid>
                <Grid item xs sm={12} >
                    <MultipleFilesUploader
                        dropEventHandler = {dropEventHandler}
                        handleSaveSelectedImages = {handleSaveSelectedImages}
                        selectedFiles = {selectedFiles}
                        loading = {loading}
                    />
                </Grid>
            </Grid>
        </Paper>
    );

}

export default Regulations;