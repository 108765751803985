import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "./Login";
import AdminRoute from "./AdminLayout";
import Dashboard from "./Dashboard";
import CheckIn from "./Checkin";
import Intervals from "./Intervals";
import Reports from "./Report";
import HotelSettings from "./Setting/index";
import SectorsImages from './SectorsImages';
import Regulations from './Regulations';
import BeachBookingManagement from "./BeachBookingManagement";
import SkyBarAppointments from "./Skybar/SkyBarAppointments";
// import LaungeChairReservationLayout from "./Lounge/LoungeChairReservationLayout";
// import LoungeChairReservation from "./LoungeChairReservation";
import { authenticationService } from "../../__services/authenticationService";

export default function Admin() {
  const [loggedUser, setLoggedUser] = useState({});

  useEffect(() => {
    authenticationService.currentUser.subscribe((x) =>
      setLoggedUser({ currentUser: x })
    );
  }, []);

  return (
    <Switch>
      <Redirect exact from="/admin" to="/admin/login" />
      <Route path="/admin/login" component={Login} />
      {loggedUser && (
        <React.Fragment>
          <AdminRoute path="/admin/dashboard" component={Dashboard} />
          <AdminRoute path="/admin/checkin" component={CheckIn} />
          <AdminRoute path="/admin/intervals" component={Intervals} />
          <AdminRoute path="/admin/reports" component={Reports} />
          <AdminRoute path="/admin/hotel-settings" component={HotelSettings} />
          <AdminRoute path="/admin/sectors-images" component={SectorsImages} />
          <AdminRoute path="/admin/regulations" component={Regulations} />
          <AdminRoute path="/admin/beach-booking-management" component={BeachBookingManagement} />
          <AdminRoute path="/admin/skybar-appointments" component={SkyBarAppointments} />
          {/* <LaungeChairReservationLayout
            path="/admin/lounge-chair-reservation"
            component={LoungeChairReservation}
          /> */}
        </React.Fragment>
      )}
    </Switch>
  );
}
