import React from "react";
import { Grid, Typography} from "@material-ui/core";
import SelectController from "components/shared/SelectController";
import InputController from "components/shared/InputController";
import { BtnCsdm } from "components/shared/Presentational";

const SunbedReservation = (props) => {

    let packageAreas = {
        standard: 'Bronz',
        superior: 'Bronz, Silver',
        gold: 'Bronz, Silver, Gold'
    };


    return (
        <React.Fragment>

            {( props.customer.package === null ) && (
                <React.Fragment>

                    <Grid container spacing={2} style={{ border: "1px dashed #b49151", padding: "1rem 0.25rem", marginTop: "1rem" }} >
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                component="h3"
                                align="left"
                                className={props.classes.infoText}
                            >
                                <h1>Pachet fara loc la plaja sau posibilitate de rezervare</h1>
                                <p>Draga {props.customer.name }, </p>
                                <p class="text-md">pachetul tau nu include sezlonguri oferite GRATUIT la plaja. Poti insa beneficia de sezlonghuri la tarife reduse la Plaja Steaua de Mare - Sector 2 prezentand cardul de la camera si numarul acesteia.</p>
                                <p class="text-md">Poti contacta deasemenea receptia pentru a achizitiona un pachet ALL INCLUSIVE ce include acest beneficiu.</p>
                                <p class="text-md">Iti multumim!</p>
                            </Typography>
                        </Grid>
                    </Grid>

                </React.Fragment>
            )}

            {( props.customer.package !== null ) && (
                <React.Fragment>
                    <Grid container spacing={2} style={{ border: "1px dashed #b49151", padding: "1rem 0.25rem", marginTop: "1rem" }} >
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                component="h3"
                                align="left"
                                className={props.classes.infoText}
                            >
                                Ai achizitionat un pachet {props.customer.package.name} si poti rezerva o umbrela cu sezlonguri la plaja sau piscina
                                complexului Hotelier Steaua de Mare in zonele: {packageAreas[props.customer.package.name]} pentru azi {props.todayString}.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "1rem" }} >
                        <Grid item md={6} sm={6} xs={12} >
                            <InputController
                                identifier='reservationCode'
                                handleChange = {props.handleReservationCodeChangeEvent}
                                placeholder='Cod rezervare'
                                labelValue='Cod rezervare'
                                isValueValid = {props.isReservationCodeValid}
                                errorMessage = {props.reservationCodeErrorMessage}
                            />
                            <br></br>
                            <SelectController
                                handleChangeSelector={props.handleChangeSelector}
                                options={props.sectorsOptions}
                                labelValue='Selectati Sectorul'
                                identifier='sectorSelector'
                            />
                            <br></br>
                            <SelectController
                                handleChangeSelector={props.handleChangeRow}
                                options={props.rowsOptions[props.selectedScene] ?? []}
                                labelValue='Selectati Randul'
                                identifier='rowSelector'
                            />
                            <br></br>
                            <SelectController
                                handleChangeSelector={props.handleChangeUmbrella}
                                options={
                                    props.selectedScene.length === 0 ? [] :
                                        (
                                            (props.umbrellasOptions[props.selectedScene] !== undefined ?
                                                (props.umbrellasOptions[props.selectedScene][props.selectedRow] ?? []) : []
                                            )
                                        )
                                }
                                labelValue='Selectati Umbrela'
                                identifier='umbrellaSelector'
                            />
                            <br></br>
                            { props.selectedUmbrella.length > 0 && (
                                <BtnCsdm fullWidth disabled={props.loading} onClick={props.handleSaveSelectedUmbrella} identifier = 'saveSelectedSunbed' >
                                    Salveaza selectia
                                </BtnCsdm>
                            ) }
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            { (props.sectorImage.length > 0)
                                && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%'
                                    }}
                                >
                                    <img
                                        src={props.sectorImage}
                                        style={{
                                            width: 'inherit',
                                            height: 'inherit'
                                        }}
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>

                </React.Fragment>
            )}

        </React.Fragment>



    );

}

export default SunbedReservation;