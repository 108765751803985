import React, { useState, useEffect } from "react";
import API from "__helpers/API";
import { useSnackbar } from "notistack";
import ErrorDisplay from "./__components/ErrorDisplay";
import SunbedReservation from "./__components/SunbedReservation";
import DisplayReservation from "./__components/DisplayReservation";


const SunbedsPlanning = ({ customer, classes }) => {

    const today = new Date();
    const todayString = today.getDate()+'/'+(today.getMonth() + 1)+'/'+today.getFullYear();
    const nowHour = parseInt(today.getHours());

    const [selectedScene, setSelectedScene] = useState('');
    const [selectedRow, setSelectedRow] = useState('');
    const [selectedUmbrella, setSelectedUmbrella] = useState('');
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [sectorsOptions, setSectorsOptions] = useState([]);
    const [rowsOptions, setRowsOptions] = useState([]);
    const [umbrellasOptions, setUmbrellasOptions] = useState([]);
    const [currentReservation, setCurrentReservation] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingCancelReservation, setLoadingCancelReservation] = useState(false);
    const [sectorsImages, setSectorsImages] = useState([]);
    const [reservationCodeValue, setReservationCodeValue] = useState('');
    const [isReservationCodeValid, setIsReservationCodeValid] = useState(true);
    const [reservationCodeErrorMessage, setReservationCodeErrorMessage] = useState('');


    const handleReservationCodeChangeEvent = event => {
        const isReservationCodeValid = isReservationCodValid(event.target.value);

        if (isReservationCodeValid === true) {
            setReservationCodeValue(event.target.value);
            setIsReservationCodeValid(true);
            return;

        }

        setReservationCodeValue('');
        setSelectedRow('');
        setSelectedUmbrella('');
        setSelectedScene('');

        const errorMessage = (event.target.value.length === 5) ? 'Codul introdus nu este valid' :
            ( event.target.value.length > 5 ) ? 'Codul de rezervare este format din 5 cifre' : '';

        if (errorMessage.length > 0) {
            setIsReservationCodeValid(false);
            setReservationCodeErrorMessage(errorMessage);
        }

    }

    const isReservationCodValid = reservationCode => {

        if (reservationCode.length !== 5) {
            return false;
        }

        const firstCodeDigit = parseInt(reservationCode[0]);
        const secondCodeDigit = parseInt(reservationCode[1]);
        const thirdCodeDigit = parseInt(reservationCode[2]);
        const forthCodeDigit = parseInt(reservationCode[3]);
        const fifthCodeDigit = parseInt(reservationCode[4]);

        const firstSum = firstCodeDigit + secondCodeDigit;
        const firstValidationKey = (firstSum < 10) ? firstSum : Math.min(firstCodeDigit, secondCodeDigit);

        if (firstValidationKey !== forthCodeDigit) {
            return false;
        }

        const secondSum = thirdCodeDigit + forthCodeDigit;
        const secondValidationKey = (secondSum < 10) ? secondSum : Math.max(thirdCodeDigit, forthCodeDigit);

        return (secondValidationKey === fifthCodeDigit);
    }

    const handleChangeSelector = (event) => {
        setSelectedScene(event.target.value);
        if (selectedUmbrella.length > 0) {
            setSelectedRow('');
            setSelectedUmbrella('');
        }
    }

    const handleChangeRow = (event) => {
        setSelectedRow(event.target.value);
        if (selectedUmbrella.length > 0) {
            setSelectedUmbrella('');
        }
    }

    const handleChangeUmbrella = (event) => {
        setSelectedUmbrella(event.target.value);
    }

    const setClientSunbedsDistributionServerData = response => {
        setSectorsOptions(response.distributions.sectorsOptions ?? []);
        setRowsOptions(response.distributions.rowsOptions ?? []);
        setUmbrellasOptions(response.distributions.umbrellasOptions ?? []);
        setCurrentReservation(response.currentReservation ?? []);

        const sectorsImages = response.distributions.sectorsImages ?? {};
        if (Object.keys(sectorsImages).length > 0) {
            for (let index in sectorsImages) {
                console.log(index);
                sectorsImages[index] = process.env.REACT_APP_API_ENDPOINT+sectorsImages[index];
            }
        }

        setSectorsImages(sectorsImages);
    }

    const handleSaveSelectedUmbrella = event => {
        setLoading(true);
        API.post('/api/guest/sunbeds/save/sunbed/reservation', {
            identifier: customer.identifier,
            selectedUmbrella: selectedUmbrella,
            reservationCode: reservationCodeValue
        })
        .then((response) => {
            setLoading(false);
            if ((response.status === true) && (response.payload.actionStatus.status === true)) {
                setCurrentReservation(response.payload.currentReservation);
            }
            else
            {
                const errorMessage = (response.payload.actionStatus.status === false) ?
                    response.payload.actionStatus.message : response.error;
                enqueueSnackbar(errorMessage, { variant: "error" });
            }

        })
        .catch((err) => {
            setLoading(false);
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

    }

    const handleCancelSunbedReservation = event => {
        setLoadingCancelReservation(true);

        API.post('/api/guest/sunbeds/cancel/sunbed/reservation', {
            reservationId: currentReservation.reservationId,
            identifier: customer.identifier,
            package: (customer.package === null) ? '' : customer.package.name
        })
        .then((response) => {
            setLoadingCancelReservation(false);
            if ((response.status === true) && (response.payload.actionStatus.status === true)) {
                setClientSunbedsDistributionServerData(response.payload);
                setSelectedRow('');
                setSelectedUmbrella('');
                setSelectedScene('');
            }
            else
            {
                const errorMessage = (response.payload.actionStatus.status === false) ?
                    response.payload.actionStatus.message : response.error;
                enqueueSnackbar(errorMessage, { variant: "error" });
            }

        })
        .catch((err) => {
            setLoadingCancelReservation(false);
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

    }

    const checkIfIsInWorkingHours = () => {
        return (nowHour >= 6 && nowHour <= 23)
    }

    useEffect(() => {

        API.post("/api/guest/sunbeds/get/distributions", {
            identifier: customer.identifier,
            package: (customer.package === null) ? '' : customer.package.name
        })
        .then((response) => {

            response.status ?
                setClientSunbedsDistributionServerData(response.payload) :
                setErrorMessage(response.error);
        })
        .catch((err) => {
            setErrorMessage("Eroare de comunicare");
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

    },[]);


    return (
        <React.Fragment>

            {(errorMessage.length > 0)
                && (
                <ErrorDisplay
                    errorTitle = 'Atentie'
                    errorMessage = {errorMessage}
                />
            )}

            {((errorMessage.length === 0)
                && (checkIfIsInWorkingHours() === false))
                && (
                    <ErrorDisplay
                        errorTitle = "In afara orarului de rezervare"
                        errorMessage = {
                            `Draga ${customer.name},\nPentru a fi echitabili fata de toti turistii complexului nostru, sistemul de rezervari online functioneaza numai in intervalul 7:00-17:00.\nTe rugam sa revii in aceasta pagina in timpul orarului de functionare pentru a efectua o rezervare.\n\n\nIti multumim!`
                        }
                    />
                )
            }

            {((errorMessage.length === 0)
                && (checkIfIsInWorkingHours() === true)
                && (Object.keys(currentReservation).length === 0))
                && (
                    <SunbedReservation
                        classes = {classes}
                        customer = {customer}
                        sectorsOptions = { (reservationCodeValue.length > 0) ? sectorsOptions : []}
                        rowsOptions = {rowsOptions}
                        selectedScene = {selectedScene}
                        umbrellasOptions = {umbrellasOptions}
                        selectedRow = {selectedRow}
                        handleSaveSelectedUmbrella = {handleSaveSelectedUmbrella}
                        handleChangeUmbrella = {handleChangeUmbrella}
                        handleChangeRow = {handleChangeRow}
                        handleChangeSelector = {handleChangeSelector}
                        loading = {loading}
                        todayString = {todayString}
                        selectedUmbrella = {selectedUmbrella}
                        sectorImage = {sectorsImages[selectedScene] ?? '/logo512.png'}
                        handleReservationCodeChangeEvent = {handleReservationCodeChangeEvent}
                        isReservationCodeValid = {isReservationCodeValid}
                        reservationCodeErrorMessage = {reservationCodeErrorMessage}
                    />
            )}

            {((errorMessage.length === 0)
                && (checkIfIsInWorkingHours() === true)
                && (Object.keys(currentReservation).length > 0))
                && (
                    <DisplayReservation
                        classes = {classes}
                        customer = {customer}
                        currentReservation = {currentReservation}
                        loadingCancelReservation = {loadingCancelReservation}
                        handleCancelSunbedReservation = {handleCancelSunbedReservation}
                    />
            )}

        </React.Fragment>
    );
}

export default SunbedsPlanning;