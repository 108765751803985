import React, { useState, useEffect } from "react";
import {
  Grid, Table, TableCell, TableHead, TableBody, TableRow, Typography,
  FormControlLabel, Radio
} from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { BtnCsdm } from "./Presentational";
import { useSnackbar } from "notistack";
import OccupancyGroup from "components/shared/Occupancy/OccupancyGroup";
import clsx from "clsx";

import API from "__helpers/API";

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: orange[50],
  },
}));


const PoolIntervals = props => {
  const customerIdentifier = props.customerIdentifier;
  const [loading, setLoading] = useState(false);
  const [intervals, setIntervals] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedInterval, setSelectedInterval] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const classes = useStyles();
  const handleChange = (event) => {
    let selectedIntervalData;
    const selectedIntervalId = event.target.value;
    intervals.map(service => {
      if (selectedIntervalId === service.identifier) {
        selectedIntervalData = service;

        return false;
      }
    });

    if ((selectedIntervalData.occupancy >= 100) && (props.isDisplayedInAdmin === false)) {
      enqueueSnackbar("Nu exista locuri disponibile.", { variant: "error" });

      return false;
    }
    setSelectedInterval(event.target.value);

  };

  const handleIntervalsubmit = () => {
    if (selectedInterval === "") {
      enqueueSnackbar("Nu ai selectat intervalul.", { variant: "error" });

      return;
    }
    setLoading(true);
    API.post(props.apiEndpointChangePoolIntervals, {
      identifier: customerIdentifier,
      interval: selectedInterval,
    })
      .then((response) => {
        setLoading(false);
        if (response.status) {
          enqueueSnackbar("Intervalul a fost schimbat", { variant: "success" });
          if (props.updateCustomer) {
            props.updateCustomer(response.payload);
          }
        } else {
          setSelectedInterval("");
          enqueueSnackbar(response.error, { variant: "error" });
        }
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar("Eroare de comunicare", { variant: "error" });
      });
  };

  useEffect(() => {
    API.post(props.apiEndpointFetchPoolIntervals, {
      identifier: customerIdentifier,
    })
      .then((response) => {
        if (response.status === true) {
          setSelectedInterval(response.payload.currentInterval);
          setIntervals(response.payload.intervals);
        }

        if (response.status === false) {
          setErrorMessage(response.error);
        }

      })
      .catch((err) => {
        enqueueSnackbar("Eroare de comunicare", { variant: "error" });
      });
  }, []);

  useEffect(() => {
    setSelectedInterval(selectedInterval);
  }, [selectedInterval]);

  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      {intervals.length > 0 && (
        <Grid item xs sm={8}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  Interval orar
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bold" }}
                >
                  Grad de ocupare
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {intervals.length > 0 &&
                intervals.map((el) => {
                  return (
                    <TableRow
                      key={el.identifier}
                      className={clsx({
                        [classes.selected]: el.identifier === selectedInterval,
                      })}
                    >
                      <TableCell style={{ padding: "1px" }}>
                        <FormControlLabel
                          value={el.identifier}
                          key={el.identifier}
                          control={<Radio />}
                          label={
                            <Typography
                              style={{
                                fontWeight: "bolder",
                                textTransform: "uppercase",
                                fontSize: "0.75rem",
                              }}
                            >
                              {el.name}
                            </Typography>
                          }
                          name="selectedInterval"
                          onChange={handleChange}
                          checked={selectedInterval === el.identifier}
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ padding: "1px" }}
                      >
                        <OccupancyGroup value={el.occupancy} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <BtnCsdm fullWidth disabled={loading} onClick={handleIntervalsubmit}>
            Salveaza selectia
          </BtnCsdm>
        </Grid>
      )}

      {intervals.length <= 0 && (
        <Grid item xs sm={12} >
          <Alert severity="error" style={{ display: 'flex', justifyContent: 'center', }} >
            <AlertTitle>Atentie</AlertTitle>
            <strong> {errorMessage} </strong>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}

export default PoolIntervals;