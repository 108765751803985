import React, { forwardRef } from "react";
import { Formik, Field, getIn } from "formik";
import { MTableEditRow, MTableEditField } from "material-table";

import {
    AddBox,
    ArrowDownward,
    LastPage,
    Check,
    Clear,
    ChevronRight,
    SaveAlt,
    ViewColumn,
    DeleteOutline,
    Edit,
    FilterList,
    ChevronLeft,
    FirstPage,
    Remove,
    Search,
} from "@material-ui/icons";

export const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const FormikEditRow = ({ onEditingApproved, validationSchema, ...props }) => {
    console.log(props.data)
    return (
        <Formik
            initialValues={props.data}
            validationSchema={validationSchema}
            onSubmit={data => {
                if (props.mode === "add" || props.mode === "update") {
                    const { tableData, ...newData } = data;
                    onEditingApproved(props.mode, newData, props.data);
                } else {
                    onEditingApproved(props.mode, data, props.data);
                }
            }}
        >
            {({ submitForm }) => (
                <MTableEditRow {...props} onEditingApproved={submitForm} />
            )}
        </Formik>
    );
};

export const FormikEditField = props => (
    <Field name={props.columnDef.field}>
        {({ field, form }) => {
            const { name } = field;
            const { errors, setFieldValue } = form;
            const showError = !!getIn(errors, name);

            return (
                <div>
                    <MTableEditField
                        {...props}
                        {...field}
                        error={showError}
                        onChange={newValue => setFieldValue(name, newValue)}
                    />
                    {errors[field.name] && (
                        <div style={{ color: "#f44336", clear: "both" }}>{errors[field.name]}</div>
                    )}
                </div>

            );
        }}
    </Field>
);