import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const BeachSectorBookingManagement = props => {

    const classes = useStyles();

    return (
        <React.Fragment>
            {(Object.keys(props.selectedSectorCells).length === 0) && (props.selectedSector.length > 0) && (
                <React.Fragment>
                    <Alert severity="error" style={{ display: 'flex', justifyContent: 'center', }} >
                        <AlertTitle>Atentie</AlertTitle>
                        Nu exista scaune configurate pentru zona selectata!
                    </Alert>
                </React.Fragment>
            )}

            {(Object.keys(props.selectedSectorCells).length > 0) && (props.selectedSector.length > 0) && (
                <div className="graph" style={{ overflowX: "auto", maxHeight: "60vh", overflowY: "auto" }}>
                    <table  style={{ width: "100%", borderCollapse: "separate", border: "1px solid #e1e4e8", borderSpacing: "10px", textAlign: "center" }}>
                        <thead>
                            <th className={`${classes.borderCell} ${classes.firstCornerCell} `} >RAND</th>
                            <th className={`${classes.borderCell} ${classes.secondCornerCell} `} >ZONA</th>
                            {
                                Object.keys(props.cells).map( cellNumber => {

                                    return <th className={`${classes.borderCell} ${classes.baseBodyCell} ${classes.headerFixRow}`} > {props.cells[cellNumber]} </th>

                                })
                            }

                        </thead>
                        <tbody>
                            {
                                Object.keys(props.selectedSectorCells).map( (rowName) => {
                                    return (
                                        <tr key={'row'+rowName} >
                                            <td className={`${classes.borderCell} ${classes.firstFixRow}`} key={'cell'+rowName} >{props.rowsAliases[rowName].alias ?? ''}</td>
                                            <td className={`${classes.borderCell} ${classes.secondFixRow}`} key={'cell'+rowName} >{props.rowsAliases[rowName].package ?? ''}</td>
                                            {
                                                Object.keys(props.selectedSectorCells[rowName]).map( (cellName) => {
                                                    return (

                                                        props.selectedSectorCells[rowName][cellName].seatType !== '0' ?

                                                            <td
                                                                key={'cell'+props.selectedSectorCells[rowName][cellName].id}
                                                                className={
                                                                    `${classes.cell} ${classes.smallText} `+
                                                                    (
                                                                        (props.reservations[props.selectedSectorCells[rowName][cellName].id] !== undefined)
                                                                        && (
                                                                            props.reservations[props.selectedSectorCells[rowName][cellName].id].is_confirmed === '0' ?
                                                                            ( `${classes.standard}` ) : ( `${classes.superior}` )
                                                                        )
                                                                    )
                                                                }
                                                                onClick = { () => { props.cellClickEvent(props.selectedSectorCells[rowName][cellName]) } }
                                                            >
                                                                {
                                                                    props.selectedSectorCells[rowName][cellName].seatType
                                                                }
                                                            </td>

                                                            :

                                                            <td className={ `${classes.unallocatedCell}` } key={'empty_cell_'+props.selectedSectorCells[rowName][cellName].id} ></td>

                                                    )
                                                })
                                            }

                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    cell: {
        padding: "5px",
        maxWidth: "15px",
        textAlign: "center",
        wordWrap: "break-word",
        cursor: "pointer",
        backgroundColor: "#dedede",
        '&:hover': {
            backgroundColor: "#b4b6ba"
        }
    },
    unallocatedCell: {
        padding: "5px",
        maxWidth: "15px",
        textAlign: "center",
        wordWrap: "break-word",
        backgroundColor: "#e1e4e8"
    },
    borderCell: {
        padding: "5px",
        textAlign: "center",
        backgroundColor: "#C0C0C0",
        minWidth: "53px",
        maxWidth: "53px"
    },
    smallText: {
        fontSize: theme.typography.pxToRem(10)
    },
    standard: {
        backgroundColor: "#6b441f",
        color: "white",
        '&:hover': {
            backgroundColor: "#a68f79"
        }
    },
    superior: {
        backgroundColor: "#52696c",
        color: "white",
        '&:hover': {
            backgroundColor: "#bac3c4"
        }
    },
    gold: {
        backgroundColor: "#d1b975",
        color: "white",
        '&:hover': {
            backgroundColor: "#e3d5ac"
        }
    },
    firstFixRow: {
        position: "sticky",
        left:"0px"
    },
    secondFixRow: {
        position: "sticky",
        left:"60px"
    },
    headerFixRow: {
        position: "sticky",
        top:"0px"
    },
    firstCornerCell: {
        position: "sticky",
        left:"0px",
        top:"0px",
        zIndex: 1
    },
    secondCornerCell: {
        position: "sticky",
        left:"60px",
        top:"0px",
        zIndex: 1
    }
}))

export default BeachSectorBookingManagement;