import React, { useEffect, useState } from "react";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import API from "__helpers/API";

const packages = ['standard', 'superior', 'gold'];

const generateCells = (max, leadingZero = false) => {
    let cells = [];
    for (let i = 1; i <= max; i++) {
        if (leadingZero && i < 10) {
            cells.push('0' + i);
        } else {
            cells.push(i);
        }
    }

    return cells;
}

const PackagesMap = ({ hotelSettings, hotel }) => {
    const [roomsPackages, setRoomsPackages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let existingMapping = {};
        if (hotelSettings) {
            packages.forEach(packageName => {
                if (hotelSettings.packages && hotelSettings.packages[packageName]?.roomsMapping) {
                    hotelSettings.packages[packageName].roomsMapping.forEach(roomNo => {
                        existingMapping[roomNo] = packageName;
                    })
                }
            })
        }

        generateCells(13).forEach(floor => {
            generateCells(17, true).forEach(room => {
                setRoomsPackages(prevState => {
                    return {
                        ...prevState,
                        [floor + "" + room]: existingMapping.hasOwnProperty([floor + "" + room]) ? existingMapping[floor + "" + room] : packages[0]
                    }
                })
            })
        })
    }, [hotelSettings]);

    const handlePackageChange = (room) => {
        const currentPackageIndex = packages.indexOf(roomsPackages[room]);
        if (currentPackageIndex === packages.length - 1) {
            setRoomsPackages(prevState => {
                return {
                    ...prevState,
                    [room]: packages[0]
                }
            });
        } else {
            setRoomsPackages(prevState => {
                return {
                    ...prevState,
                    [room]: packages[currentPackageIndex + 1]
                }
            });
        }
    }

    const handleMappingSaving = () => {
        setIsSubmitted(true);
        API.post("/api/receptie/hotel/settings/mapping", {
            hotel,
            roomsMapping: roomsPackages
        })
            .then((response) => {
                if (response.status) {
                    enqueueSnackbar("Maparea camerelor a fost salvate.", { variant: "success" });
                } else {
                    enqueueSnackbar(response.error, { variant: "error" });
                }
                setIsSubmitted(false);
            })
            .catch((err) => {
                enqueueSnackbar(`Ops, ceva nu a mers bine. ${err.msg}`, { variant: "error" });
                setIsSubmitted(false);
            });
    }

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
                <div className="graph">
                    <table style={{ width: "100%", borderCollapse: "separate", border: "1px solid #e1e4e8", borderSpacing: "10px", textAlign: "center" }}>
                        <thead>
                            <tr>
                                <th className={`${classes.cell} ${classes.smallText}`}>Camera / Etaj</th>
                                {generateCells(17, true).map(room => {
                                    return <th className={`${classes.cell}`} key={room + Math.random()}>{room}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                generateCells(13).map((floor, idx) => {
                                    return (
                                        <tr key={floor + idx}>
                                            <td className={`${classes.cell}`}>{floor}</td>
                                            {generateCells(17, true).map(room => {
                                                return <td
                                                    key={room + Math.random()}
                                                    onClick={() => handlePackageChange(floor + "" + room)}
                                                    className={`${classes.cell} ${classes.smallText} 
                                                ${roomsPackages[floor + "" + room] ? classes[roomsPackages[floor + "" + room]] : ''}
                                                `}>{floor}{room}</td>
                                            })}
                                        </tr>
                                    )
                                })
                            }
                            <tr></tr>
                        </tbody>
                    </table>
                </div>
            </Grid>
            <Grid item>
                <Button disabled={isSubmitted} variant="contained" color="primary" onClick={() => handleMappingSaving()}>Salveaza maparea</Button>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    cell: {
        padding: "5px",
        maxWidth: "15px",
        textAlign: "center",
        wordWrap: "break-word",
        cursor: "pointer",
        backgroundColor: "#e1e4e8",
        '&:hover': {
            backgroundColor: "#b4b6ba"
        }
    },
    smallText: {
        fontSize: theme.typography.pxToRem(10)
    },
    standard: {
        backgroundColor: "#6b441f",
        color: "white",
        '&:hover': {
            backgroundColor: "#a68f79"
        }
    },
    superior: {
        backgroundColor: "#52696c",
        color: "white",
        '&:hover': {
            backgroundColor: "#bac3c4"
        }
    },
    gold: {
        backgroundColor: "#d1b975",
        color: "white",
        '&:hover': {
            backgroundColor: "#e3d5ac"
        }
    }
}))

export default PackagesMap;