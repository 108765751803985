import React, { useEffect, useState } from "react";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Dialog , Button,  IconButton ,Typography }from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import InputController from "components/shared/InputController";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      minWidth: '30vw'
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);


const ConfirmClientReservationModal = props => {

    return (
        <Dialog onClose={props.handleCloseModal} aria-labelledby="customized-dialog-title" open={props.openClientConfirmationModal}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleCloseModal}>
                Confirmare rezervare
            </DialogTitle>
            <DialogContent dividers>

                <InputController
                    identifier='reservationCode'
                    handleChange = {props.handleInputChangeEvent}
                    placeholder='Cod rezervare'
                    labelValue='Cod rezervare'
                    isValueValid = {props.isReservationCodeValid}
                    errorMessage = {props.reservationCodeErrorMessage}
                />

            </DialogContent>
            <DialogActions>
                <Button disabled={props.confirmClientReservationButtonState} onClick={props.handleCloseModal} variant="contained" color="default">
                    Inchide
                </Button>
                <Button disabled={props.confirmClientReservationButtonState} onClick={props.handleConfirmReservation} variant="contained" color="primary">
                    Confirma
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default ConfirmClientReservationModal;