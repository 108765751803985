import React from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";

const InputController = (props) => {

    return (
        <Grid item xs={12} >

            <FormControl
                key={props.identifier+ "Controller"}
                variant="outlined"
                fullWidth
            >
                {(props.isValueValid === false) && (
                    <TextField
                        error
                        name={props.identifier}
                        onChange={props.handleChange ?? (() => {} )}
                        key= {props.identifier+ "Input"}
                        placeholder={props.placeholder}
                        helperText={props.errorMessage}
                    />
                )}

                {(props.isValueValid === true) && (
                    <TextField
                        name={props.identifier}
                        onChange={props.handleChange ?? (() => {} )}
                        key= {props.identifier+ "Input"}
                        placeholder={props.placeholder}
                    />
                )}

            </FormControl>

        </Grid>
    );
}

export default InputController;