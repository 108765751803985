import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Link,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import { makeStyles } from "@material-ui/core/styles";
import { mealTypes } from "./../../../__helpers/Utils";
import TableCellMeal from "./TableCellMeal";
import TableRowMealTypes from "./TableRowMealTypes";
import CustomersEntriesOnMealType from './CustomersEntriesOnMealType';
import API from "./../../../__helpers/API";

export default function ReportAdultsAndChildsLoad(props) {
  const selectedDate = props.selectedDate;
  const [objectiveLoads, setObjectiveLoads] = useState({});
  const [showCustomersEntries, setShowCustomersEntries] = useState(false);
  const [dataForEntries, setDataForEntries] = useState({ objective: null, mealType: null });
  const classes = useStyles();

  useEffect(() => {
    fetchObjectiveLoad();
  }, [selectedDate]);

  const fetchObjectiveLoad = () => {
    API.post("/api/receptie/reports/today-adults-childs-loads", {
      selectedDate: selectedDate.format("Y-MM-DD"),
    })
      .then((response) => {
        setObjectiveLoads(response);
      })
      .catch((err) => { });
  };

  const showEntries = (objective, mealType) => {
    setDataForEntries({ objective, mealType });
    updateIsOpen(true);
  }

  const updateIsOpen = (state) => {
    setShowCustomersEntries(state);
  }

  return (
    <React.Fragment>
      {Object.keys(objectiveLoads).map((obj, objIdx) => (
        <Paper
          elevation={2}
          className="Paper"
          style={{ marginBottom: "1rem" }}
          key={objIdx * 999999999}
        >
          <Table>
            <TableHead>
              <TableRowMealTypes />
              <TableRow>
                <TableCell
                  align="center"
                  style={{ maxWidth: "150px" }}
                  className={`${classes.headCell} ${classes.objective}`}
                >
                  {obj}
                </TableCell>
                {mealTypes.map((meal, mealIdx) => {
                  return objectiveLoads[obj][meal]["intervals"].map(
                    (intv, idxIntv) => (
                      <TableCell
                        key={
                          idxIntv +
                          mealIdx +
                          Math.floor(Math.random() * 9999999999)
                        }
                        align="center"
                        className={classes.headCell}
                      >
                        <p
                          style={{
                            fontSize: "9px",
                            color: "#9e9e9e",
                            margin: "2px",
                          }}
                        >
                          {intv.startTime}
                        </p>
                        <p
                          style={{
                            fontSize: "9px",
                            color: "#9e9e9e",
                            margin: "2px",
                          }}
                        >
                          {intv.endTime}
                        </p>

                        <p>{intv.eventName}</p>
                      </TableCell>
                    )
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.headCell}>
                  <PersonIcon />
                  / <ChildFriendlyIcon />
                </TableCell>
                {mealTypes.map((meal, mealIdx) => (
                  <React.Fragment
                    key={mealIdx + Math.floor(Math.random() * 9999999)}
                  >
                    {objectiveLoads[obj][meal]["intervals"].map(
                      (intv, idxIntv) => (
                        <TableCellMeal
                          key={idxIntv + Math.floor(Math.random() * 9999999)}
                        >
                          {intv["load"]["adults"]} / {intv["load"]["childs"]}
                        </TableCellMeal>
                      )
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className={classes.headCell}>
                  <TransferWithinAStationIcon />
                </TableCell>
                {mealTypes.map((meal, mealIdx) => (
                  <React.Fragment
                    key={mealIdx + Math.floor(Math.random() * 9999999)}
                  >
                    {objectiveLoads[obj][meal]["intervals"].map(
                      (intv, idxIntv) => (
                        <TableCellMeal
                          key={idxIntv + Math.floor(Math.random() * 9999999)}
                        >
                          {intv["load"]["uniques"]}
                        </TableCellMeal>
                      )
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  className={classes.headCell}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Total
                  <PersonIcon /> + <ChildFriendlyIcon />
                </TableCell>
                {mealTypes.map((meal, mealIdx) => (
                  <TableCellMeal
                    colSpan={4}
                    key={mealIdx + Math.floor(Math.random() * 9999999)}
                  >
                    {objectiveLoads[obj][meal]["total"]["total"]}
                  </TableCellMeal>
                ))}
              </TableRow>
              <TableRow>
                <TableCell
                  className={classes.headCell}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Total
                  <TransferWithinAStationIcon />
                </TableCell>
                {mealTypes.map((meal, mealIdx) => (
                  <TableCellMeal
                    colSpan={4}
                    key={mealIdx + Math.floor(Math.random() * 9999999)}
                  >
                    <Link href="#" onClick={() => showEntries(obj, meal)}>{objectiveLoads[obj][meal]["total"]["uniques"]}</Link>
                  </TableCellMeal>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      ))}
      <CustomersEntriesOnMealType
        isOpen={showCustomersEntries}
        dataForEntries={dataForEntries}
        selectedDate={props.selectedDate}
        updateIsOpen={updateIsOpen}
      />
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  head: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  headCell: {
    fontSize: theme.typography.pxToRem(10),
    textTransform: "uppercase",
    fontWeight: "bold",
    border: "1px solid #ccc",
    padding: theme.spacing(0.5, 1),
    lineHeight: 1.5,
  },
  odd: {
    backgroundColor: theme.palette.grey[100],
  },
  objective: {
    backgroundColor: "#06213e",
    color: "white",
  },
}));
