import axios from "axios";
import { authenticationService } from "__services/authenticationService";

/**
 * parse response
 */
const parseBody = (response) => {
  if (response.status === 200) {
    return response.data;
  }
};

let instance = axios.create({
  // baseURL: "https://meseaqvatonic.csdm.ro",
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: "json",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});
// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const loggedUser = authenticationService.currentUserValue;
  if (loggedUser && loggedUser.token) {
    config.headers.Authorization = `Bearer ${loggedUser.token}`;
  }

  return config;
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return parseBody(response);
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // return Promise.reject(error)
    if (error.response) {
      if (error.response.status === 403) {
        authenticationService.logout();
        window.location.reload(true);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
