import React from "react";
import { Grid, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

const SelectController = props => {

    return (
        <Grid item xs={12} >

            {props.options.length > 0 && (
                <FormControl
                    key={props.identifier+ "Controller"}
                    variant="outlined"
                    fullWidth
                >
                    <InputLabel
                        key= {props.identifier+ "Label"}
                    >
                        {props.labelValue}
                    </InputLabel>
                    <Select
                        name={props.identifier}
                        onChange={props.handleChangeSelector}
                        key= {props.identifier+ "Select"}
                        label={props.labelValue}
                    >
                        {props.options.map((element) => {
                                return (
                                    <MenuItem key= {props.identifier+ "option"+element.value} value={element.value}>{element.name}</MenuItem>
                                );
                            }
                        )}
                    </Select>

                </FormControl>
            )}

        </Grid>
    );
};

export default SelectController;