import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API from "./../../../__helpers/API";

export default function PoolLoad(props) {
  const selectedDate = props.selectedDate;
  const showPercent = props.showPercent;
  const [poolIntervalsData, setPoolIntervalsData] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    fetchPoolIntervalsData();
  }, [selectedDate]);

  const fetchPoolIntervalsData = () => {
    API.post("/api/receptie/reports/today-pool-load", {
      selectedDate: selectedDate.format("Y-MM-DD"),
    })
      .then((response) => {
        setPoolIntervalsData(response.payload.intervals);
      })
      .catch((err) => { });
  };

  return (

    <React.Fragment>
        <Paper
          elevation={2}
          className="Paper"
          style={{ marginBottom: "1rem" }}
          key='pool-occupancy-paper-id'
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            style={{ maxWidth: "150px" }}
                            className={`${classes.headCell} ${classes.objective}`}
                            key="pool-cell-key"
                        >
                        Piscina
                        </TableCell>

                        {poolIntervalsData.map( intervalData => {
                            return <TableCell
                                key={'header '+intervalData.identifier}
                                align="center"
                                className={classes.headCell}
                            >
                                <p> {intervalData.name} </p>
                            </TableCell>
                        })}

                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell
                            key={Math.floor(Math.random() * 9999999)}
                            className={classes.headCell}
                        >
                            <p>Grad incarcare</p>
                        </TableCell>

                        {poolIntervalsData.map( intervalData => {
                            return <TableCell
                                key={'body '+intervalData.identifier}
                                align="center"
                                className={classes.headCell}
                            >
                                <p> { showPercent ? intervalData.occupancy+'%' : intervalData.baseValue} </p>
                            </TableCell>
                        })}

                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    </React.Fragment>

  );
}

const useStyles = makeStyles((theme) => ({
  head: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  headCell: {
    fontSize: theme.typography.pxToRem(10),
    textTransform: "uppercase",
    fontWeight: "bold",
    border: "1px solid #ccc",
    padding: theme.spacing(0.5, 1),
    lineHeight: 1.5,
  },
  odd: {
    backgroundColor: theme.palette.grey[100],
  },
  objective: {
    backgroundColor: "#06213e",
    color: "white",
  },
}));
