import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import API from "./../../__helpers/API";
import Interval from "./__components/Interval";

export default function Intervals() {
  const classes = useStyles();
  const [hotelObjectives, setHotelObjectives] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchObjectives();
  }, []);

  const fetchObjectives = () => {
    API.get("/api/receptie/objectives")
      .then((response) => {
        setHotelObjectives(response);
        setLoading(false);
      })
      .catch((err) => { });
  }

  const forceRefetch = () => {
    setHotelObjectives([]);
    fetchObjectives();
  }

  return (
    <div className={classes.root}>
      {!loading && (
        <Paper elevation={2} className={classes.paper}>
          <Grid container spacing={2} alignItems="center" direction="row">
            {hotelObjectives.map((hotel, hotelIdx) =>
              Object.keys(hotel.objectives).map((objectiveId, key) => (
                <Grid item xs={12} key={key + hotelIdx}>
                  <p
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      backgroundColor: "#06213e",
                      padding: "1.15rem .25rem",
                      color: "white",
                    }}
                  >
                    {hotel.hotel} - {hotel.objectives[objectiveId].name}
                  </p>
                  {hotel.objectives[objectiveId].intervals.map(
                    (interval, intvKey) => (
                      <Card
                        className={clsx(classes.interval, {
                          [classes.odd]: intvKey % 2 === 0,
                        })}
                        key={intvKey}
                      >
                        <CardContent className={classes.intervalContent}>
                          <Interval interval={interval} forceRefetch={forceRefetch} />
                        </CardContent>
                      </Card>
                    )
                  )}
                </Grid>
              ))
            )}
          </Grid>
        </Paper>
      )}
      {loading && <CircularProgress />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2),
  },
  interval: {
    margin: theme.spacing(1, 0),
  },
  intervalContent: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    "&:last-child": {
      paddingBottom: "5px",
    },
  },
  leftContent: {},
  rightContent: {
    display: "flex",
    flex: 1,
  },
  infoContent: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-around",
  },
  odd: {
    backgroundColor: theme.palette.grey[100],
  },
}));
