import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { authenticationService } from "../../__services/authenticationService";
import { BtnCsdm, GridCsdm, CsdmBox } from "../shared/Presentational";

import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { login } from "../../actions/LoginActions";

const validationSchema = Yup.object({
  hotel: Yup.string().required("Nu ai ales hotelul unde esti cazat"),
  username: Yup.string()
    .min(3, "Minim 3 caracter")
    .required("Campul este obligatoriu"),
  roomNo: Yup.string()
    .min(2, "Minim 2 caractere")
    .required("Campul este obligatoriu"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    height: "calc(100vh - 40px)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  group: {
    width: "auto",
    height: "auto",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default function Login() {
  const classes = useStyles();
  let history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    login({ me: "viorels" });
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <GridCsdm item xs={false} sm={4} className={classes.image}>
        <CsdmBox>
          <BtnCsdm
            component={Link}
            to="/guest/rules"
            variant="outlined"
            size="large"
            fullWidth
          >
            Regulament
          </BtnCsdm>
          <BtnCsdm
            component={Link}
            to="/"
            variant="outlined"
            size="large"
            fullWidth
          >
            Ecran Principal
          </BtnCsdm>
          <Box mt={2}>
            <Typography
              style={{ fontSize: "0.75rem", color: "white" }}
              variant="body2"
              component="p"
              align="center"
            >
              {"Copyright "}
              Table Manager by Webtailors {new Date().getFullYear()}
            </Typography>
          </Box>
        </CsdmBox>
      </GridCsdm>
      <Grid item xs={12} sm={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h6">
            AUTENTIFICARE
          </Typography>

          <Formik
            initialValues={{ username: "", roomNo: "", hotel: "A" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setSubmitted(true);
              authenticationService
                .loginGuest(values.username, values.roomNo, values.hotel)
                .then((response) => {
                  setSubmitted(false);
                  if (!response.status) {
                    enqueueSnackbar(response.error, { variant: "error" });
                  } else {
                    history.push("/guest/meals-intervals");
                  }
                })
                .catch((err) => {
                  setSubmitted(false);
                  enqueueSnackbar("Ceva nu a mers bine!" + err.message, {
                    variant: "error",
                  });
                });
            }}
          >
            {(props) => {
              const {
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
              } = props;
              return (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  noValidate
                >
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        id="username"
                        label="Nume de familie"
                        name="username"
                        error={errors.username && touched.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.username && touched.username && errors.username
                        }
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        size="small"
                        name="roomNo"
                        label="Numar de camera"
                        type="text"
                        id="roomNo"
                        error={errors.roomNo && touched.roomNo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.roomNo && touched.roomNo && errors.roomNo
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl component="fieldset" fullWidth>
                        {errors.hotel && touched.hotel && (
                          <FormLabel
                            component="legend"
                            error={errors.hotel && touched.hotel}
                          >
                            Alege hotelul unde esti cazat
                          </FormLabel>
                        )}

                        <RadioGroup
                          row
                          aria-label="hotel"
                          name="hotel"
                          value={values.hotel}
                          className={classes.group}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="M"
                            control={<Radio />}
                            label="MEDUZA"
                          />
                          <FormControlLabel
                            value="D"
                            control={<Radio />}
                            label="DELFINUL"
                          />
                          <FormControlLabel
                            value="A"
                            control={<Radio />}
                            label="AQVATONIC"
                            checked="A"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <BtnCsdm
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    size={"large"}
                    disabled={submitted}
                  >
                    Acceseaza
                  </BtnCsdm>
                </form>
              );
            }}
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
}
