import React from "react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import moment from "moment";

export default function TimePick(props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <TimePicker {...props} />
    </MuiPickersUtilsProvider>
  );
}
