import React from "react";
import PropTypes from 'prop-types';
import {
    Accordion as Acc, AccordionSummary as AccSummary, AccordionDetails as AccDetails,
    Typography, makeStyles
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = ({ title, children, ...rest }) => {
    const classes = useStyles();

    return (
        <Acc {...rest} elevation={0}>
            <AccSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{title}</Typography>
            </AccSummary>
            <AccDetails>
                {children}
            </AccDetails>
        </Acc>
    );
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(14),
        textTransform: "uppercase"
    }
}));

Accordion.propTypes = {
    title: PropTypes.string
};

export default Accordion;