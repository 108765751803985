import React, { useEffect, useState, forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Slide,
    Typography,
    AppBar,
    Dialog,
    Toolbar,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MaterialTable from "material-table";
import { tableIcons } from "../../shared/TableUtils";
import API from "./../../../__helpers/API";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
        backgroundColor: "#06213e",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomersEntriesOnMealType(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(props.isOpen);
    const [customers, setCustomers] = useState([]);
    const handleClose = () => {
        setOpen(false);
        props.updateIsOpen();
    };

    useEffect(() => {
        API.post("/api/receptie/interval/customers-entries", {
            objective: props.dataForEntries.objective,
            mealType: props.dataForEntries.mealType,
            selectedDate: props.selectedDate.format("Y-MM-DD"),
        })
            .then((response) => {
                if (response.status) {
                    setCustomers(response.payload);
                }
            })
            .catch((err) => {
                console.log(err.mesage);
            });
        setOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Intrari clienti in {props.dataForEntries.objective} la {props.dataForEntries.mealType} pentru {props.selectedDate.format("DD-MM-Y")}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Grid container alignItems="center">
                    <Grid item xs>
                        <MaterialTable
                            title=""
                            rowsPerPage={20}
                            icons={tableIcons}
                            columns={[
                                { title: "Nr. camera", field: "roomNo" },
                                { title: "Client", field: "name" },
                                { title: "Ora intrare", field: "entryHour" },
                            ]}
                            data={customers}
                            options={{
                                pageSize: 1000,
                                maxBodyHeight: 'calc(100vh - 130px)',
                                paging: false,
                                rowStyle: rowData => ({
                                    backgroundColor: (rowData.tableData.id % 2 === 0) ? '#fafafa' : '#fff'
                                })
                            }}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </React.Fragment>
    );
}
