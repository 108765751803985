import { Alert, AlertTitle } from "@material-ui/lab";
import { Grid} from "@material-ui/core";

const ErrorDisplay = (props) => {

    return (<Grid item xs sm={12} >
        <Alert severity="error" style={{ display: 'flex', justifyContent: 'center', }} >
            <AlertTitle>{props.errorTitle}</AlertTitle>
            <div style={{ whiteSpace: "pre" }}>{props.errorMessage}</div>
        </Alert>
    </Grid>)

}

export default ErrorDisplay;