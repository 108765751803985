import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { BtnCsdm, GridCsdm, CsdmBox } from "../shared/Presentational";
import API from "__helpers/API";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    height: "calc(100vh - 40px)",
  },
}));

export default function Rules() {

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [rulesImages, setRulesImages] = useState([]);

  const setImagesFromRequest = filesList => {

    let extendedFilesList = [];
    for (let file of filesList) {
      extendedFilesList.push( process.env.REACT_APP_API_ENDPOINT+file);
    }

    setRulesImages(extendedFilesList);
  };

  useEffect(() => {

      API.get("/api/gui/setting/rules", {})
        .then((response) => {

          response.status ?
            setImagesFromRequest(response.payload) :
            enqueueSnackbar(response.error, { variant: "error" });

        })
        .catch((err) => {
            enqueueSnackbar("Eroare de comunicare", { variant: "error" });
        });

  },[]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <GridCsdm item xs={false} sm={4} className={classes.image}>
        <CsdmBox>
          <BtnCsdm
            component={Link}
            to="/guest"
            variant="outlined"
            size="large"
            fullWidth
          >
            Sejurul meu
          </BtnCsdm>
          <BtnCsdm
            component={Link}
            to="/"
            variant="outlined"
            size="large"
            fullWidth
          >
            Ecran Principal
          </BtnCsdm>
        </CsdmBox>
      </GridCsdm>
      <Grid item xs={12} sm={8} component={Paper} elevation={6} square>
        <div className={classes.paper + " Example__container__document"}>
          {rulesImages.map((file, key) => (

            <div
                style={{
                  width: '100%',
                  height: '100%'
                }}
                key={'file-container'+key}
            >
                <img
                    src={file}
                    alt={'file'+key}
                    key={'file'+key}
                    style={{
                      width: 'inherit',
                      height: 'inherit'
                    }}
                />
            </div>

          ))}
        </div>
      </Grid>
    </Grid>
  );
}
