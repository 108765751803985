import React, { useState, useEffect } from "react";
import { Grid, Typography, Tabs, Tab, withStyles } from "@material-ui/core";
import IdleTimer from "react-idle-timer";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import API from "__helpers/API";
import { authenticationService } from "__services/authenticationService";
import CustomerPackageInfo from "components/guest/__components/CustomerPackageInfo";
import IntervalMealPlanner from "components/guest/__components/MealPlanning";
import SkyBarBooking from "components/guest/__components/SkyBarBooking";
import SpaPlanner from "components/guest/__components/PoolPlanning";
import SunbedsPlanning from "components/guest/__components/SunbedsPlanning";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}

    >
      {value === index && (
        <React.Fragment>
          <Typography>{children}</Typography>
        </React.Fragment>
      )}
    </div>
  );
}

const CsdmTabs = withStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0)
  },
  indicator: {
    backgroundColor: '#b49151',
  },
}))(Tabs);

const CsdmTab = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 1, 1, 1),
    textTransform: 'uppercase',
    color: 'black',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 2,
    marginRight: theme.spacing(3),
    '&$selected': {
      color: "#b49151",
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function Dashboard() {
  const classes = useStyles();
  let history = useHistory();
  const [idleTimer, setIdleTimer] = useState(null);
  const [customer, setCustomer] = useState({});
  const loggedUser = authenticationService.currentUserValue;
  const [activeSection, setActiveSection] = useState(0);

  useEffect(() => {
    API.post("/api/guest/customer/search", {
      identifier: loggedUser.token,
    }).then((response) => {
      if (response.status) {
        setCustomer(response.payload);
      }
    });
  }, [loggedUser]);

  const onAction = (e) => { };

  const onActive = (e) => { };

  const onIdle = (e) => {
    authenticationService.logout();
    history.push("/welcome");
  };

  const handleActiveTabChange = (_, newSection) => {
    setActiveSection(newSection);
  }

  return (
    <div>
      <IdleTimer
        ref={(ref) => {
          setIdleTimer(ref);
        }}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={1000 * 30}
      />
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.content}
        justify="center"
      >
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="h1"
            className={`${classes.infoText} ${classes.spacer}`}
            align="center"
          >
            Salut {customer.name}! Iti  multumim ca esti oaspetele nostru in
            perioada {customer.checkIn} / {customer.checkOut}.
          </Typography>
          <CustomerPackageInfo customer={customer} classes={classes} />
          <CsdmTabs value={activeSection} onChange={handleActiveTabChange}>
            <CsdmTab label="Mese" />
            <CsdmTab label="Bazin" />
            <CsdmTab label="Plaja" />
            {
              customer.hasSkyBar && (
                <CsdmTab label="Skybar" />
              )
            }

          </CsdmTabs>
          <TabPanel value={activeSection} index={0}>
            <IntervalMealPlanner customer={{ ...customer, identifier: loggedUser.token }} classes={classes} />
          </TabPanel>
          <TabPanel value={activeSection} index={1}>
            <SpaPlanner customer={{ ...customer, identifier: loggedUser.token }} classes={classes} />
          </TabPanel>
          <TabPanel value={activeSection} index={2}>
            <SunbedsPlanning customer={{ ...customer, identifier: loggedUser.token }} classes={classes} />
          </TabPanel>
          <TabPanel value={activeSection} index={3}>
            <SkyBarBooking customerIdentifier={loggedUser.token} classes={classes} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(1),
  },
  spacer: {
    margin: theme.spacing(1, 0),
  },
  infoText: {
    fontWeight: "bold",
    color: "#06213e",
    letterSpacing: "1px",
    fontSize: ".90rem",
  },
  infoTextSpecial: {
    color: "#06213e",
    backgroundColor: "#fff3e0",
    border: "1px dashed #b49151",
    padding: "10px",
    fontStyle: "italic",
    fontSize: ".90rem",
  },
}));