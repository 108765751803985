import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { mealTypes } from "../../../__helpers/Utils";

export default function TableRowMealTypes() {
  return (
    <TableRow>
      <TableCell>{""}</TableCell>
      {mealTypes.map((meal, mealIdx) => (
        <TableCell
          key={mealIdx + Math.floor(Math.random() * 9999999999)}
          colSpan={4}
          align="center"
        >
          {meal}
        </TableCell>
      ))}
    </TableRow>
  );
}
