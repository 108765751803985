import { BehaviorSubject } from "rxjs";
import API from "__helpers/API";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

function login(username, password) {
  return API.post(`/api/login/employee`, { username, password }).then(
    (response) => {
      if (response.status) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", JSON.stringify(response.payload));
        currentUserSubject.next(response.payload);
      }

      return response;
    }
  );
}

function loginGuest(username, roomNo, hotel) {
  return API.post(`/api/login/guest`, { username, roomNo, hotel }).then(
    (response) => {
      if (response.status) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("currentUser", JSON.stringify(response.payload));
        currentUserSubject.next(response.payload);
      }

      return response;
    }
  );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}

export const authenticationService = {
  login,
  loginGuest,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};
