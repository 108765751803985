import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { authenticationService } from "../../__services/authenticationService";

const validationSchema = Yup.object({
  username: Yup.string()
    .min(3, "Minim 3 caracter")
    .required("Campul este obligatoriu"),
  password: Yup.string()
    .min(6, "Minim 6 caractere")
    .required("Campul este obligatoriu"),
});

export default function Login() {
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (authenticationService.currentUserValue) {
      history.push("/admin/checkin");
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          AUTENTIFICARE
        </Typography>

        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setSubmitted(true);
            authenticationService
              .login(values.username, values.password)
              .then((response) => {
                setSubmitted(false);
                if (!response.status) {
                  enqueueSnackbar(response.error, { variant: "error" });
                } else {
                  history.push("/admin/dashboard");
                }
              })
              .catch((err) => {
                setSubmitted(false);
                enqueueSnackbar("Ceva nu a mers bine!" + err.message, {
                  variant: "error",
                });
              });
          }}
        >
          {(props) => {
            const {
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form
                className={classes.form}
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Utilizator"
                  name="username"
                  autoFocus
                  error={errors.username && touched.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.username && touched.username && errors.username
                  }
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Parola"
                  type="password"
                  id="password"
                  error={errors.password && touched.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size={"large"}
                  disabled={submitted}
                >
                  Sign In
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://webtailors.ro">
            Table Manager by Webtailors
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
