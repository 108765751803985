import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import SelectController from "components/shared/SelectController";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const SectorsImagesComponent = (props) => {

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <h1 className="PageHeadline">Setare Imagini Zone Plaja</h1>
            </Grid>

            <Grid item md={4} sm={12} xs={12} >
                <React.Fragment>
                    <SelectController
                        handleChangeSelector={props.handleChangeSectorsSelector}
                        options={props.sectorsData}
                        labelValue='Selectati Sectorul'
                        identifier='sectorSelector'
                    />
                </React.Fragment>

                <br></br>

                {((props.selectedSector.length > 0) && (props.sectorImage.length > 0)) && (

                    <React.Fragment>
                        <Grid item xs={12} >
                            <input
                                color="primary"
                                accept="image/*"
                                type="file"
                                onChange={props.handleChangeSectorImage}
                                id="icon-button-file"
                                style={{ display: 'none', }}
                                value={props.sectorImageInputValue}
                            />
                            <label htmlFor="icon-button-file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    size="large"
                                    color="primary"
                                    disabled={props.loading}
                                >
                                    <CloudUploadIcon /> <span className="button-text" >&nbsp; Inlocuieste imaginea</span>
                                </Button>
                            </label>
                        </Grid>
                    </React.Fragment>
                )}

            </Grid>

            <Grid item md={8} sm={12} xs={12} >
                {((props.selectedSector.length > 0) && (props.sectorImage.length > 0)) && (
                    <React.Fragment>
                        <div
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <img
                                src={`${process.env.REACT_APP_API_ENDPOINT + props.sectorImage}`}
                                style={{
                                    width: 'inherit',
                                    height: 'inherit'
                                }}
                            />

                        </div>
                    </React.Fragment>
                )}

                {((props.selectedSector.length > 0) && (props.sectorImage.length === 0)) && (
                    <React.Fragment>
                        <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            onChange={props.handleChangeFileInput}
                            id="icon-button-file"
                            style={{ display: 'none', }}
                            value={props.inputValue}
                        />
                        <label htmlFor="icon-button-file">
                            <Button
                                variant="contained"
                                component="span"
                                size="large"
                                color="primary"
                                disabled={props.loading}
                            >
                                <CloudUploadIcon /> &nbsp; Incarcare imagine
                            </Button>
                        </label>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default SectorsImagesComponent;