import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

import {
  MealNotAvailableCell,
  MealSelectedCell,
} from "../../shared/mealCells";

import MealSelectable from "./MealSelectable";
import MoveAllMeals from "./MoveAllMeals";
import API from "./../../../__helpers/API";

export default function MealPlanner(props) {
  const classes = useStyles();
  const [allocations, setAllocations] = useState(props.allocations);
  const [objectiveLoad] = useState(props.objectiveLoad);
  const [mealInfo, setMealInfo] = useState([]);
  const customerIdentifier = props.customerIdentifier;
  const handleAllocationChange = (allocations) => {
    setAllocations(allocations);
  };

  useEffect(() => {
    API.post("/api/receptie/interval/list", { hotel: props.hotel })
      .then((response) => {
        if (response.status) {
          setMealInfo(response.payload);
        }
      })
      .catch((err) => { });
    setAllocations(props.allocations);
  }, [props.allocations]);

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs>
        <TableContainer
          component={Paper}
          className={classes.tablePlannerContainer}
        >
          <Table
            className={classes.table}
            aria-label="table planner"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {Object.keys(mealInfo).map((meal, key) => (
                  <TableCell align="center" key={key} colSpan={4}>
                    {meal}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Ora</TableCell>
                {Object.keys(mealInfo).map((meal) =>
                  mealInfo[meal].map((hour, key) => (
                    <MoveAllMeals
                      align="right"
                      key={key}
                      starthour={hour}
                      customerIdentifier={customerIdentifier}
                      mealType={meal}
                      handleAllocationChange={handleAllocationChange}
                    >
                      {hour}
                    </MoveAllMeals>
                  ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(allocations).map((date, key) => (
                <TableRow key={key}>
                  <TableCell key={key}>{date}</TableCell>
                  {Object.keys(mealInfo).map((meal) =>
                    mealInfo[meal].map((hour, key) => {
                      if (
                        Object.prototype.hasOwnProperty.call(
                          allocations[date],
                          meal
                        )
                      ) {
                        if (allocations[date][meal]["eventStart"] === hour) {
                          return (
                            <MealSelectedCell key={key} align="center">
                              {objectiveLoad[date][meal][hour]}%
                            </MealSelectedCell>
                          );
                        } else {
                          return (
                            <MealSelectable
                              key={key}
                              align="center"
                              occupancy={objectiveLoad[date][meal][hour]}
                            />
                          );
                        }
                      } else {
                        return (
                          <MealNotAvailableCell key={key} align="center" />
                        );
                      }
                    })
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  headline: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  wrapHead: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  tablePlannerContainer: {
    maxHeight: 500,
  },
}));
