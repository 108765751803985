import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import background from './img/background.png'

export default function NestedGrid() {
const classes = useStyles();
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Marea</h3>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${background}) ,linear-gradient(237deg,#fff 0%,#fff 100%) !important`,
    backgroundSize: 'cover',
    minHeight: '10vh',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignOtems: 'center'
  },
  title:{
    //margin: 0,
    padding:0,
    textAlign:'center'
  }
}));
