import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { tableIcons } from "components/shared/TableUtils";
import API from "__helpers/API";

const SkyBarAppointments = () => {
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        const fetchAppointments = () => {
            API.get("/api/receptie/skybar/reservations")
                .then((response) => {
                    setAppointments(response);
                })
                .catch((err) => { });
        }
        fetchAppointments();
    }, [])

    return (
        <Grid container alignItems="center">
            <Grid item xs>
                <MaterialTable
                    title="Lista programari SkyBar"
                    rowsPerPage={50}
                    icons={tableIcons}
                    columns={[
                        { title: "Nr. camera", field: "roomNo" },
                        { title: "Client", field: "name" },
                        { title: "Ora", field: "reservedFor" },
                        { title: "Hotel", field: "hotel" },
                    ]}
                    data={appointments}
                    options={{
                        pageSize: 50,
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default SkyBarAppointments;