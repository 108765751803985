import React, { useEffect, useState } from "react";
import Dropzone from 'react-dropzone'
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { BtnCsdm } from "components/shared/Presentational";

const MultipleFilesUploader = props => {

    return (
        <React.Fragment>
            <React.Fragment>
                <Dropzone onDrop={props.dropEventHandler}>
                    {({getRootProps, getInputProps}) => (
                        <section  >
                            <div {...getRootProps( {className: 'drop-zone-container'} )}>
                                <input {...getInputProps()} />
                                { (props.selectedFiles.length === 0) && (
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                )}
                                { (props.selectedFiles.length > 0) && (
                                    <ImageList rowHeight={160} cols={3}>
                                        {props.selectedFiles.map((item) => (
                                            <ImageListItem key={item.path} cols={item.cols || 1}>
                                                <img src={URL.createObjectURL(item)} alt={item.name} />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>
            </React.Fragment>
            <React.Fragment>
            { (props.selectedFiles.length > 0) && (
                <BtnCsdm fullWidth disabled={props.loading} onClick={props.handleSaveSelectedImages} identifier = 'saveSelectedSunbed' >
                    Salveaza selectia
                </BtnCsdm>
            )}
            </React.Fragment>
        </React.Fragment>
    );

}

export default MultipleFilesUploader;