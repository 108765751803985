import React, { useState } from "react";
import { Grid, Checkbox } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";

import IntervalEdit from "./IntervalEdit";

export default function Interval(props) {
  const classes = useStyles();
  const [interval, setInterval] = useState(props.interval);
  const [intervalEditOpen, setIntervalEditOpen] = useState(false);

  const handleIntervalEditOpen = (state) => {
    setIntervalEditOpen(state);
    if (state === false) {
      props.forceRefetch();
    }
  };

  const updateInterval = (interval) => {
    setInterval(interval);
  };

  return (
    <Grid container alignItems="center">
      <IntervalEdit
        open={intervalEditOpen}
        interval={interval}
        updateInterval={updateInterval}
        handleIntervalEditOpen={handleIntervalEditOpen}
      />
      <Grid item xs={6} sm={3}>
        <span
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {interval.name}{" "}
        </span>
        <span
          style={{
            fontSize: "0.75rem",
            fontStyle: "italic",
          }}
        >
          ({interval.aliasToMeal})
        </span>
      </Grid>
      <Grid item xs>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs className={classes.head}>
                Start
              </Grid>
              <Grid item xs className={classes.head}>
                Final
              </Grid>
              <Grid item xs className={classes.head}>
                Capacitate
              </Grid>
              <Grid item xs className={classes.head}>
                Compensare start
              </Grid>
              <Grid item xs className={classes.head}>
                Permite trecerea pe tip de masa
              </Grid>
              <Grid item xs className={classes.head}>
                Operatii
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs>
                {interval.start}
              </Grid>
              <Grid item xs>
                {interval.end}
              </Grid>
              <Grid item xs>
                {interval.capacity}
              </Grid>
              <Grid item xs>
                {interval.intervalCompensation} (min)
              </Grid>
              <Grid item xs>
                <Checkbox
                  checked={interval.gatePassOnMealType === true ? true : false}
                  disabled
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Grid>
              <Grid item xs>
                <IconButton
                  size="small"
                  aria-label="edit"
                  onClick={() => setIntervalEditOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  head: {
    color: theme.palette.grey[700],
    fontStyle: "italic",
  },
}));
