import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import { BtnCsdm } from "../../shared/Presentational";

export default function ClientInfo(props) {
  const classes = useStyles();
  const customer = props.customer;
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" spacing={3}>
          <div className={classes.wrapHead}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                component="h1"
                className={classes.headline}
              >
                Informatii client
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div className={classes.buttons}>
                <BtnCsdm
                  type="button"
                  variant="contained"
                  color="primary"
                  size={"small"}
                  onClick={() => props.updateCustomer({})}
                >
                  <ArrowBack /> Inapoi
                </BtnCsdm>
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          disabled
          value={customer.name}
          size="small"
          label="Nume client"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.roomNo}
          label="Camera"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.noOfAdults}
          label="Nr. adulti"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.noOfChilds}
          label="Nr. copii"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.mealType}
          label="Tip de masa"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.checkIn}
          label="Check In"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.checkOut}
          label="Check Out"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          variant="outlined"
          disabled
          size="small"
          value={customer.bracelets}
          label="Bratari asociate"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headline: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  wrapHead: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
}));
