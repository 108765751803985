import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import logoSdm from "./../img/complex-logo.png";
import Color from "color";
import { makeStyles } from "@material-ui/core/styles";
import "./../index.scss";
import { BtnCsdm } from "./shared/Presentational";

export default function Welcome() {
  const classes = useStyles();

  return (
    <Grid
      container
      component="main"
      className="WelcomeHolder"
      style={{
        backgroundImage: "url(https://source.unsplash.com/collection/8958441)",
        backgroundSize: "cover",
      }}
    >
      <CssBaseline />
      <Card className={classes.holder} elevation={4}>
        <CardContent>
          <img className={classes.logo} src={logoSdm} alt="logoSdm" />
          <Typography
            align="center"
            variant="body1"
            component="h1"
            className={classes.goldText}
          >
            Apasa pe unul dintre cele doua butoane pentru a incepe.
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs>
              <BtnCsdm
                component={Link}
                to="/guest/rules"
                variant="outlined"
                size="large"
                fullWidth
                className={classes.btnStart}
              >
                Regulament
              </BtnCsdm>
            </Grid>
            <Grid item xs>
              <BtnCsdm
                component={Link}
                to="/guest"
                variant="outlined"
                size="large"
                fullWidth
                className={classes.btnStart}
              >
                Sejurul meu
              </BtnCsdm>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  holder: {
    maxWidth: 700,
    height: 400,
    zIndex: 100,
    padding: theme.spacing(1, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Color("#06213e").alpha(0.9).string(),
  },
  button: {
    margin: theme.spacing(2, 0.2),
  },
  welcome: {
    textTransform: "uppercase",
    color: "#06213e",
    textAlign: "center",
    padding: theme.spacing(1, 1),
  },
  infoText: {
    color: "#b49151",
    letterSpacing: 0.4,
    margin: theme.spacing(1),
  },
  logo: {
    maxWidth: "90%",
    margin: theme.spacing(0.3, "auto"),
  },
  goldText: {
    color: "#b49151",
    textTransform: "uppercase",
    fontSize: "1.1rem",
    letterSpacing: "1px",
    fontWeight: "bolder",
    marginBottom: theme.spacing(4),
  },
}));
