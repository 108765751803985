import React, { useEffect } from "react";
import { Route, Redirect, useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

import {
  Spa as SpaIcon,
  Settings as SettingsIcon,
  Today as TodayIcon,
  ChevronRight as ChevronRightIcon,
  AccountCircle,
  BeachAccess,
  AccessTime,
  PermMedia,
  LibraryAddCheck,
  Apps
} from "@material-ui/icons";



import Button from "@material-ui/core/Button";
import clsx from "clsx";

import { authenticationService } from "../../__services/authenticationService";
import Can from "../../__helpers/Can";

export default function AdminLayout({ component: Component, ...rest }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [loggedUser, setLoggedUser] = React.useState({});
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    authenticationService.logout();
    history.push("/admin/login");
  };

  useEffect(() => {
    setLoggedUser(authenticationService.currentUserValue);
  }, []);

  const drawer = (
    <List>
      <Can
        role={loggedUser.role}
        perform="customer:checkin"
        yes={() => (
          <ListItem button component={Link} to="/admin/checkin">
            <ListItemIcon>
              <DirectionsWalkIcon />
            </ListItemIcon>
            <ListItemText primary="CheckIn" />
          </ListItem>
        )}
      />
      <Can
        role={loggedUser.role}
        perform="reports:list"
        yes={() => (
          <ListItem button component={Link} to="/admin/reports">
            <ListItemIcon>
              <TodayIcon />
            </ListItemIcon>
            <ListItemText primary="Rapoarte mese" />
          </ListItem>
        )}
      />
      <Can
        role={loggedUser.role}
        perform="intervals:list"
        yes={() => (
          <ListItem button component={Link} to="/admin/intervals">
            <ListItemIcon>
              <AccessTime />
            </ListItemIcon>
            <ListItemText primary="Intervals" />
          </ListItem>
        )}
      />
      <Can
        role={loggedUser.role}
        perform="skybar:appointments"
        yes={() => (
          <ListItem button component={Link} to="/admin/skybar-appointments">
            <ListItemIcon>
              <LocalConvenienceStoreIcon />
            </ListItemIcon>
            <ListItemText primary="Programari Skybar" />
          </ListItem>
        )}
      />
      <Can
        role={loggedUser.role}
        perform="spa:programari"
        yes={() => (
          <ListItem
            button
            component={Link}
            to="/admin/spa-programator-terapeut"
          >
            <ListItemIcon>
              <SpaIcon />
            </ListItemIcon>
            <ListItemText primary="SPA" />
          </ListItem>
        )}
      />

      <Can
        role={loggedUser.role}
        perform="hotel:settings"
        yes={() => (
          <ListItem button component={Link} to="/admin/hotel-settings">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Setari Hotel" />
          </ListItem>
        )}
      />

      <Can
        role={loggedUser.role}
        perform="hotel:settings"
        yes={() => (
          <ListItem button component={Link} to="/admin/sectors-images">
            <ListItemIcon>
              <LibraryAddCheck />
            </ListItemIcon>
            <ListItemText primary="Imagini Zone Plaja" />
          </ListItem>
        )}
      />

      <Can
        role={loggedUser.role}
        perform="hotel:settings"
        yes={() => (
          <ListItem button component={Link} to="/admin/regulations">
            <ListItemIcon>
              <PermMedia />
            </ListItemIcon>
            <ListItemText primary="Regulament" />
          </ListItem>
        )}
      />

      <Can
        role={loggedUser.role}
        perform="hotel:settings"
        yes={() => (
          <ListItem button component={Link} to="/admin/beach-booking-management">
            <ListItemIcon>
              <Apps />
            </ListItemIcon>
            <ListItemText primary="Gestiune Rezervari Plaja" />
          </ListItem>
        )}
      />

      <Can
        role={loggedUser.role}
        perform="beach:reservation"
        yes={() => (
          <ListItem button component={Link} to="/admin/lounge-chair-reservation">
            <ListItemIcon>
              <BeachAccess />
            </ListItemIcon>
            <ListItemText primary="Rezervari Shezlonguri" />
          </ListItem>
        )}
      />

    </List>
  );

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Contul meu</MenuItem> */}
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!authenticationService.currentUserValue) {
          return <Redirect to={{ pathname: "/admin/login" }} />;
        }
        return (
          <div className={classes.root}>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ fontWeight: "bolder" }}
                >
                  Complex Hotelier Steaua De Mare
                </Typography>
                <div className={classes.grow} />
                <Button
                  edge="end"
                  aria-label="contul meu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {loggedUser.firstName}
                  <AccountCircle />
                </Button>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
              <Divider />
              {drawer}
            </Drawer>
            <main className={classes.content}>
              <Toolbar />
              <Container maxWidth="lg">
                <Component {...matchProps} />{" "}
              </Container>
            </main>
            {renderProfileMenu}
          </div>
        );
      }}
    />
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#06213e",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "#b49151",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0.5),
  },
  grow: {
    flexGrow: 1,
  },
}));
