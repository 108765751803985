import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import GuestLayout from "./GuestLayout";
import LaungeChairReservationLayout from "./LaungeChairReservationLayout";
import GuestRules from "./Rules";
import GuestLogin from "./Login";
import GuestSpaLogin from "./SpaLogin";
import GuestIntervalMealPlanner from "./Dashboard";
import LoungeChairReservation from "./LoungeChairReservation";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

export default function Guest() {
  return (
    <Switch>
      <Redirect exact from="/guest" to="/guest/login" />
      <Route path="/guest/rules" component={GuestRules} />
      <Route path="/guest/login" component={GuestLogin} />
      <Route path="/guest/spaLogin" component={GuestSpaLogin} />
      <GuestLayout
        path="/guest/meals-intervals"
        component={GuestIntervalMealPlanner}
      />
      <LaungeChairReservationLayout
        path="/guest/lounge-chair-reservation"
        component={LoungeChairReservation}
      />
    </Switch>
  );
}
