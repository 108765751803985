import React from "react";
import { Grid, Button, FormControl, TextField, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import { packageTypes } from "__helpers/Utils";
import TimePick from "components/shared/TimePick";
import API from "__helpers/API";

const GlobalSettings = ({ hotelsSettings, hotel }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Grid container direction="row">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    checkOutHour: moment(hotelsSettings[hotel]?.checkOutHour, "HH:mm") || moment().format("HH:mm"),
                    gateAllowedIps: "",
                    intervalTimeBeforeChange: hotelsSettings[hotel]?.intervalTimeBeforeChange || 0,
                    packages: {
                        "standard": {
                            description: hotelsSettings[hotel]?.packages?.standard?.description || "",
                        },
                        "superior": {
                            description: hotelsSettings[hotel]?.packages?.superior?.description || "",
                        },
                        "gold": {
                            description: hotelsSettings[hotel]?.packages?.gold?.description || "",
                        },
                    }
                }}
                onSubmit={(values) => {
                    API.post("/api/receptie/hotel/settings/global", {
                        hotel,
                        checkOutHour: values.checkOutHour.format("HH:mm"),
                        ...values
                    })
                        .then((response) => {
                            if (response.status) {
                                enqueueSnackbar("Setarile au fost salvate.", { variant: "success" });
                            } else {
                                enqueueSnackbar(response.error, { variant: "error" });
                            }
                        })
                        .catch((err) => {
                            enqueueSnackbar(`Ops, ceva nu a mers bine. ${err.msg}`, { variant: "error" });
                        });
                }}
            >
                {(props) => {
                    const {
                        touched,
                        errors,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                        submitted,
                        values,
                    } = props;
                    return (
                        <form
                            className={classes.form}
                            onSubmit={handleSubmit}
                            autoComplete="off"
                            noValidate
                        >
                            <Grid container spacing={1} direction="row">
                                <Grid item xs={12}>
                                    <h2 className="PageHeadline" style={{ fontSize: "0.75rem", marginBottom: "0.5rem", borderBottom: "1px solid #ccc" }}>Setari generale hotel</h2>
                                </Grid>
                                <Grid item xs sm={6}>
                                    <FormControl
                                        error={errors.end && touched.end}
                                        fullWidth
                                    >
                                        <TimePick
                                            label="Ora Checkout"
                                            value={values.checkOutHour}
                                            inputVariant="outlined"
                                            size="small"
                                            ampm={false}
                                            minutesStep={5}
                                            onChange={(val) =>
                                                setFieldValue("checkOutHour", val)
                                            }
                                            format="HH:mm"
                                            views={["hours", "minutes"]}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs sm={6}>
                                    <FormControl
                                        error={errors.end && touched.end}
                                        fullWidth
                                    >
                                        <TextField
                                            label="Clientul poate schimba intervalul inainte de incepere cu"
                                            variant="outlined"
                                            size="small"
                                            value={values.intervalTimeBeforeChange}
                                            format="HH:mm"
                                            views={["hours", "minutes"]}
                                            helperText="0 = oricand; se exprima in minute"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <h2 className="PageHeadline" style={{ fontSize: "0.75rem", marginBottom: "0.5rem", borderBottom: "1px solid #ccc" }}>Setari {hotel} Pachete</h2>
                                </Grid>
                                <Grid item xs sm={12}>
                                    {
                                        packageTypes.map(packageName => (
                                            <div key={packageName + Math.random()}>
                                                <p style={{ flex: 1, fontWeight: "bold", paddingRight: "1rem" }}>Pachet {packageName}</p>
                                                <TextField
                                                    label="Descriere"
                                                    variant="outlined"
                                                    multiline={true}
                                                    rows={10}
                                                    size="small"
                                                    fullWidth={true}
                                                    name={`packages.${packageName}.description`}
                                                    value={values["packages"][packageName]["description"]}
                                                    onChange={handleChange}
                                                    helperText="se pot folosi taguri HTML: <bold></bold>, <ul></ul>...."
                                                />
                                            </div>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs sm={4}>
                                    <Button type="submit" color="primary" variant="contained" disabled={submitted}>Salveaza</Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }}
            </Formik>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({}));

export default GlobalSettings;