import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Grid
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import moment from "moment";

import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import API from "./../../../__helpers/API";

const validationSchema = Yup.object({
  name: Yup.string().required("Campul este obligatoriu"),
  start: Yup.date().required("Campul este obligatoriu"),
  end: Yup.date()
    .when(
      "start",
      (start, schema) => start && schema.min(start, "mai pune bo$$")
    )
    .required("Campul este obligatoriu"),
  aliasToMeal: Yup.string().nullable(),
  capacity: Yup.number("Nu este numar")
    .min(0, "Mai mare sau egal cu zero")
    .required("Campul este obligatoriu"),
  intervalCompensation: Yup.number("Nu este numar").min(
    0,
    "Mai mare sau egal cu zero"
  ),
  gatePassOnMealType: Yup.string(),
});

export default function IntervalEdit(props) {
  const [open, setOpen] = useState(props.open);
  const [interval, setInterval] = useState(props.interval);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleIntervalEditClose = () => {
    props.handleIntervalEditOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <Dialog
        open={open}
        aria-labelledby="form-editare-interval"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="form-editare-interval">
          EDITARE {interval.name}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: interval.name,
              aliasToMeal: interval.aliasToMeal,
              capacity: interval.capacity,
              start: moment(interval.start, "HH:mm:ss"),
              end: moment(interval.end, "HH:mm:ss"),
              intervalCompensation: interval.intervalCompensation,
              gatePassOnMealType: interval.gatePassOnMealType,
              applyCompToAll: false,
              applyCapToAll: false
            }}
            onSubmit={(values) => {
              API.post("/api/receptie/objective/edit-interval", {
                name: values.name,
                aliasToMeal: values.aliasToMeal,
                capacity: values.capacity,
                start: values.start.format("HH:mm:ss"),
                end: values.end.format("HH:mm:ss"),
                objIdentifier: interval.objIdentifier,
                identifier: interval.identifier,
                intervalCompensation: values.intervalCompensation,
                gatePassOnMealType: values.gatePassOnMealType,
                applyCompToAll: values.applyCompToAll,
                applyCapToAll: values.applyCapToAll
              })
                .then((response) => {
                  handleIntervalEditClose();
                  if (response.status) {
                    enqueueSnackbar("Datele au fost salvate.", {
                      variant: "success",
                    });
                    setInterval(response.payload);
                    props.updateInterval(response.payload);
                  } else {
                    enqueueSnackbar(response.error, { variant: "error" });
                  }
                })
                .catch((err) => {
                  enqueueSnackbar("Ceva nu a mers bine.", { variant: "error" });
                });
            }}
            validationSchema={validationSchema}
          >
            {(props) => {
              const {
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                values,
              } = props;
              return (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={values.name}
                    label="Nume interval"
                    name="name"
                    autoFocus
                    error={errors.name && touched.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.name && touched.name && errors.name}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.selectedHotel && touched.selectedHotel}
                  >
                    <InputLabel>Selecteaza tip masa</InputLabel>
                    <Select
                      name="aliasToMeal"
                      value={values.aliasToMeal}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Nu se aplica</em>
                      </MenuItem>
                      <MenuItem value={"MD"}>MD</MenuItem>
                      <MenuItem value={"PRANZ"}>Pranz</MenuItem>
                      <MenuItem value={"CINA"}>Cina</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.aliasToMeal &&
                        touched.aliasToMeal &&
                        errors.aliasToMeal}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.start && touched.start}
                  >
                    <TimePicker
                      margin="normal"
                      name="start"
                      format="HH:mm:ss"
                      views={["hours", "minutes", "seconds"]}
                      ampm={false}
                      value={values.start}
                      label="Interval start"
                      error={errors.start && touched.start}
                      helperText={errors.start && touched.start && errors.start}
                      onChange={(val) => setFieldValue("start", val)}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.end && touched.end}
                  >
                    <TimePicker
                      margin="normal"
                      format="HH:mm:ss"
                      views={["hours", "minutes", "seconds"]}
                      name="end"
                      value={values.end}
                      ampm={false}
                      label="Interval stop"
                      error={errors.end && touched.end}
                      helperText={errors.end && touched.end && errors.end}
                      onChange={(val) => setFieldValue("end", val)}
                    />
                  </FormControl>
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Capacitate"
                        name="capacity"
                        value={values.capacity}
                        error={errors.capacity && touched.capacity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.capacity && touched.capacity && errors.capacity
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.applyCapToAll}
                              onChange={handleChange}
                              name="applyCapToAll"
                            />
                          }
                          label="Aplica valoarea pentru toate intervalele obiectivului"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Compensare start"
                        name="intervalCompensation"
                        value={values.intervalCompensation}
                        error={
                          errors.intervalCompensation &&
                          touched.intervalCompensation
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.intervalCompensation &&
                          touched.intervalCompensation &&
                          errors.intervalCompensation
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.applyCompToAll}
                              onChange={handleChange}
                              name="applyCompToAll"
                            />
                          }
                          label="Aplica valoarea pentru toate intervalele obiectivului"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.gatePassOnMealType}
                          onChange={handleChange}
                          name="gatePassOnMealType"
                        />
                      }
                      label="Permite trecerea pe tip de masa, daca clientul are un singur tip de masa"
                    />
                  </FormGroup>
                  <DialogActions>
                    <Button onClick={handleIntervalEditClose} color="primary">
                      Renunta
                    </Button>
                    <Button type="submit" color="primary">
                      Modifica
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
}
