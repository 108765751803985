import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import API from "../../../__helpers/API";
import { useSnackbar } from "notistack";

export default function CustomerPackageInfo(props) {
  const classes = useStyles();
  const customer = props.customer;
  const { enqueueSnackbar } = useSnackbar();

  const handlePackageUpdate = (newPackage) => {
    API.post(`/api/receptie/customers/customer/change-package`, {
      'identifier': customer.identifier,
      'newPackage': newPackage
    }).then(response => {
      if (response.status) {
        enqueueSnackbar("Pachetul a fost schimbat.", {
          variant: "success",
        });
        props.updateCustomer({ ...customer, package: { name: newPackage, description: "" } })
      } else {
        enqueueSnackbar(response.error, { variant: "error" });
      }
    })
      .catch(err => {
        enqueueSnackbar(err.msg, { variant: "error" });
      })
  }

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12}>
        <div style={{ display: "flex", justifyItems: "center" }}>
          <Typography
            variant="body1"
            component="h1"
            className={`${classes.infoText} ${classes.spacer}`}
            align="center"
            style={{ padding: "1.25rem 0.5rem" }}
          >
            Pachetul aferent sejurului este {' '}
            <span style={{ fontWeight: "bold", fontSize: "1.35rem", textTransform: "uppercase" }}>
              {customer.package ? customer.package.name : 'NU EXISTA PACHET ASOCIAT'}
            </span>
          </Typography>
          <div style={{ display: "flex", flex: 1, justifyContent: "space-evenly" }}>
            <Button variant="contained" color="primary" size="small"
              onClick={() => handlePackageUpdate('Standard')}>
              Schimba in Standard
            </Button>

            <Button variant="contained" color="primary" size="small"
              onClick={() => handlePackageUpdate('Superior')}>
              Schimba in Superior
            </Button>

            <Button variant="contained" color="primary" size="small"
              onClick={() => handlePackageUpdate('Gold')}>
              Schimba in GOLD
        </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  headline: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  wrapHead: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
}));
