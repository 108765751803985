import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Paper,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

import moment from "moment";
import DatePick from "../../../shared/DatePick";
import SwitchComponents from "../../../shared/SwitchComponents";
import ReportIntervalLoad from "../../__components/ReportIntervalLoad";
import ReportAdultsAndChildsLoad from "../../__components/ReportAdultsAndChildsLoad";
import PoolLoad from "../../__components/PoolLoad";

export default function ReportsMeals() {
  const classes = useStyles();
  const [showPercent, setShowPercent] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedDate, handleDateChange] = useState(moment());
  const [selectedReport, setSelectedReport] = useState(
    "report-adults-and-child-load"
  );
  const handleshowPercentage = (event) => {
    setShowPercent(event.target.checked ? true : false);
  };

  const handleShowReport = (event) => {
    setSelectedReport(event.target.value);
  };

  useEffect(() => {
    if (selectedDate.isBefore(moment(), 'day') === true) {
      setSelectedReport('report-load')
    }

    setShowHistory(selectedDate.isBefore(moment(), 'day'));
  }, [selectedDate])

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={2} className="Paper">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <p className="PageHeadline" style={{ marginRight: "1rem" }}>
                Raportare pentru ziua
              </p>
              <DatePick
                autoOk
                size="small"
                variant="inline"
                inputVariant="outlined"
                label="Selecteaza data"
                format="DD/MM/yyyy"
                value={selectedDate}
                onChange={(date) => handleDateChange(date)}
              />
            </div>
            <Grid container direction="row">
              <Grid item xs sm={12}>
                <FormGroup>
                  <RadioGroup
                    name="report"
                    value={selectedReport}
                    row
                    onChange={handleShowReport}
                  >
                    <FormControlLabel
                      value="report-load"
                      control={<Radio />}
                      label="Raport incarcare mese"
                    />
                    {showHistory === false && (
                      <FormControlLabel
                        value="report-adults-and-child-load"
                        control={<Radio />}
                        label="Raport situatie curenta"
                      />
                    )}

                  </RadioGroup>
                </FormGroup>
              </Grid>
              <Grid item xs sm={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showPercent}
                        onChange={handleshowPercentage}
                        color="primary"
                      />
                    }
                    label="Afisare procentuala"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <SwitchComponents active={selectedReport}>
            <ReportIntervalLoad
              name="report-load"
              selectedDate={selectedDate}
              showPercent={showPercent}
            />
            <ReportAdultsAndChildsLoad
              name="report-adults-and-child-load"
              selectedDate={selectedDate}
              showPercent={showPercent}
            />
          </SwitchComponents>
        </Grid>
        <Grid item xs={12} >
          <PoolLoad
            selectedDate={selectedDate}
            showPercent={showPercent}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));
