import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";

import Welcome from "./Welcome";
import AdminLogin from "./admin/Admin";
import Guest from "./guest/Guest";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <Switch>
            <Redirect exact from="/" to="/welcome" />
            <Route path="/welcome" component={Welcome} />
            <Route path="/admin" component={AdminLogin} />
            <Route path="/guest" component={Guest} />
          </Switch>
        </SnackbarProvider>
      </React.Fragment>
    );
  }
}

export default Home;
